import React, { useState, useEffect } from 'react';
import Button from "./Button.js";
import { useStopwatch } from "react-use-precision-timer";
//import ImageMarker from "react-image-marker";

import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';



const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}




function MapQuestion({ currentQuestion, syncAnswer, currentQuestionState, ws, currentPoints, nTeamMembers, mapFile }) {
  const [localactiveAnswer, setLocalActiveAnswer] = useState()
  const stopwatch = useStopwatch();
  const [suggestionDict, setsuggestionDict] = useState({})

  const [suggestionAnswer, setSuggestion] = useState();
  //console.log("simple Question")
  //console.log("1:" + JSON.stringify(currentQuestion, null, 2));
  //console.log("syncAnswer:" + JSON.stringify(syncAnswer))


  var activeAnswer = syncAnswer.selectedAnswer;
  var lockedIn = syncAnswer.lockedIn;
  var questionStateText = "";

  //const [coords, setCoords] = useState({x: 0, y: 0});
  const [marker, setMarker] = useState([]);
  const [teamMarkers, setTeamMarkers] = useState([]);



  var i = 0
  //className="image-marker__marker image-marker__marker--default"

  const CustomMarker = () => {
    const name = Object.keys(suggestionDict)[i]
    i++
    console.log(name)
    return (
      <div>
        <div className='markertext'>
          {name}
        </div>
        <div style={{color:"red"}}>X
        </div>
      </div>
    );
  };





  function AddMarker(marker) {

    console.log("add marker")
    setMarker([marker])
    suggestToTeam([marker])
  }


  function suggestToTeam(marker) {

    const answerMsg = { "type": "PselectAnswer", "selectedAnswer": marker, "questionID": currentQuestion.questionID }
    if (ws.readyState === WebSocket.OPEN && lockedIn === false) {
      console.log("send selection")
      ws.send(JSON.stringify(answerMsg));
    }

  }



  function submitAnswer() {
    if (marker !== undefined && marker.length) {
      const answerMsg = { "type": "Panswer", "questionID": currentQuestion.questionID, "buzzerTime": stopwatch.getElapsedStartedTime() / 1000, "answerData": marker }
      if (ws.readyState === WebSocket.OPEN) {
        console.log("send answer")
        ws.send(JSON.stringify(answerMsg));
      }
    }
  }

  useEffect(() => {
    if (currentQuestionState === questionState.OPEN) {
      console.log("stopwatch started")
      stopwatch.start()
    }

  }, [currentQuestionState]);



  useEffect(() => {
    if (syncAnswer.selectedAnswer !== -1) {
      const updatedValue = { [syncAnswer.sugessor]: syncAnswer }
      setsuggestionDict(suggestionDict => ({
        ...suggestionDict, ...updatedValue
      }))
    }

  }, [syncAnswer]);





  useEffect(() => {
    var teamMarkers = []
    if (Object.keys(suggestionDict).length > 0) {
      {
        Object.entries(suggestionDict).map(([key, value]) => {

          if (value.hasOwnProperty("selectedAnswer")) {
            if (suggestionDict[key]["selectedAnswer"].length)
              teamMarkers.push(suggestionDict[key]["selectedAnswer"][0])
          }
        }

        )
      }
    }
    setTeamMarkers(teamMarkers)
  }, [suggestionDict]);



  var active = 0;
  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:
      active = currentQuestion.correctAnswer;

      break;
    case questionState.OPEN:
      active = 0;

      break;
    case questionState.CLOSED:

      break
    case questionState.SHOWPOINTS:

      break
    default:
      active = 0;
      break

  }


  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:

      return (
        <div className='mediaWindow'>



          <div className="playerQuestionCatClosed">{currentQuestion.category}</div>
          <div className="playerQuestionClosed">{currentQuestion.question}</div>

            <ImageMarker
              src={`data:image/jpeg;base64,${mapFile}`}
              markers={teamMarkers}
              onAddMarker={(marker) => AddMarker(marker)}
              markerComponent={CustomMarker}
            />

          
        </div>
      )


      break;
    case questionState.OPEN:
      const emptysug = nTeamMembers - Object.keys(suggestionDict).length
      var emptySugDivs = []
      for (let index = 0; index < emptysug; index++) {
        emptySugDivs.push("")

      }

      return (
        <div>
          <div className="playerQuestionCat">{currentQuestion.category}</div>
          <div className="playerQuestion" >{currentQuestion.question}</div>

          <div className='mediaWindow'>

            <ImageMarker
              src={`data:image/jpeg;base64,${mapFile}`}
              markers={teamMarkers}
              onAddMarker={(marker) => AddMarker(marker)}
              markerComponent={CustomMarker}
            />

          </div>

          <Button onClick={() => submitAnswer()} style={{ backgroundColor: (lockedIn) ? "gray" : "#b55400" }}>Submit</Button>

        </div>
      )
      break;
    case questionState.CLOSED:

      return (
        <div>
          <div className="playerQuestionCatClosed">{currentQuestion.category}</div>
          <div className="playerQuestionClosed">{currentQuestion.question}</div>
          <div className='mediaWindow'>

            <ImageMarker
              src={`data:image/jpeg;base64,${mapFile}`}
              markers={teamMarkers}
              onAddMarker={(marker) => AddMarker(marker)}
              markerComponent={CustomMarker}
            />

          </div>

        </div>
      )

      break
    case questionState.SHOWPOINTS:

      return (
        <div>


          <div className={currentPoints > 0 ? "playerCatAnswerCorrect" : "playerCatAnswerWrong"}>{currentQuestion.category}</div>
          <div className={currentPoints > 0 ? "playerAnswerCorrect" : "playerAnswerWrong"}>{currentQuestion.question}</div>


        </div>
      )

      break
    default:
      break

  }



}
export default (MapQuestion);