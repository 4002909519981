import React, { useState, useEffect } from 'react';
import Button from "./Button.js";
import { useStopwatch } from "react-use-precision-timer";




const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}




function FreeTextQuestion({ currentQuestion, syncAnswer, currentQuestionState, ws, currentPoints, nTeamMembers }) {
  const [localactiveAnswer, setLocalActiveAnswer] = useState()
  const stopwatch = useStopwatch();
  const [suggestionDict, setsuggestionDict] = useState({})

  const [suggestionAnswer, setSuggestion] = useState();
  console.log("simple Question")
  //console.log("1:" + JSON.stringify(currentQuestion, null, 2));
  console.log("syncAnswer:" + JSON.stringify(syncAnswer, null, 2))


  var activeAnswer = syncAnswer.selectedAnswer;
  var lockedIn = syncAnswer.lockedIn;
  var questionStateText = "";




  function suggestion(event) {
    //console.log(event.target.value);
    const filter = event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s]/gi, '').substring(0, 30);;
    setSuggestion(filter)
    //sug=event.target.value;
  }



  function suggestToTeam() {
    if (suggestionAnswer !== undefined && suggestionAnswer !== "") {
      const answerMsg = { "type": "PselectAnswer", "selectedAnswer": suggestionAnswer, "questionID": currentQuestion.questionID }
      if (ws.readyState === WebSocket.OPEN && lockedIn === false) {
        console.log("send selection")
        ws.send(JSON.stringify(answerMsg));
      }
    }
  }



  function submitAnswer() {
    if (suggestionAnswer !== undefined && suggestionAnswer !== "") {
    const answerMsg = { "type": "Panswer", "questionID": currentQuestion.questionID, "buzzerTime": stopwatch.getElapsedStartedTime() / 1000, "answerData": suggestionAnswer }
    if (ws.readyState === WebSocket.OPEN) {
      console.log("send answer")
      ws.send(JSON.stringify(answerMsg));
    }
  }
  }

  useEffect(() => {
    if (currentQuestionState === questionState.OPEN) {
      console.log("stopwatch started")
      stopwatch.start()
    }

  }, [currentQuestionState]);



  useEffect(() => {
    if (syncAnswer.selectedAnswer !== -1) {
      const updatedValue = { [syncAnswer.sugessor]: syncAnswer }
      setsuggestionDict(suggestionDict => ({
        ...suggestionDict, ...updatedValue
      }))
    }
    //console.log(suggestionDict)
  }, [syncAnswer]);



  var active = 0;
  


  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:
      active = currentQuestion.correctAnswer;
      
      break;
    case questionState.OPEN:
      active = 0;
     
      break;
    case questionState.CLOSED:
      
      break
    case questionState.SHOWPOINTS:
     
      break
    default:
      active = 0;
      break

  }


  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:

      return (
        <div>


          <div className="playerQuestionCatClosed">{currentQuestion.category}</div>
          <div className="playerQuestionClosed">{currentQuestion.question}</div>


          {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}

        </div>
      )


      break;
    case questionState.OPEN:
      const emptysug = nTeamMembers - Object.keys(suggestionDict).length
      var emptySugDivs = []
      for (let index = 0; index < emptysug; index++) {
        emptySugDivs.push("")

      }

      
      return (
        <div>
          <div className={currentQuestionState === 1 ? "playerQuestionCat" : "playerQuestionCatClosed"}>{currentQuestion.category}</div>
          <div className={currentQuestionState === 1 ? "playerQuestion" : "playerQuestionClosed"}>{currentQuestion.question}</div>

          {Object.entries(suggestionDict).map(([key, value]) =>
            <div className='suggestion'>Suggestion from {key}: {value.selectedAnswer}<br /></div>
          )}

          {Object.entries(emptySugDivs).map(([key, value]) =>
            <div><br /></div>
          )}

          <input onChange={suggestion} name="solution" value={suggestionAnswer} placeholder="Enter your answer" disabled={lockedIn ? true : false} />

          <Button onClick={() => suggestToTeam()} style={{ backgroundColor: (lockedIn) ? "gray" : "#b55400" }}>Send suggestion to my team</Button>
          <Button onClick={() => submitAnswer()} style={{ backgroundColor: (lockedIn) ? "gray" : "#b55400" }}>Submit</Button>

        </div>
      )



      break;
    case questionState.CLOSED:
   
      return (
        <div>

          <div className="playerQuestionCatClosed">{currentQuestion.category}</div>
          <div className="playerQuestionClosed">{currentQuestion.question}</div>



          {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}

        </div>
      )

      break
    case questionState.SHOWPOINTS:
     
      return (
        <div>


          <div className={currentPoints > 0 ? "playerCatAnswerCorrect" : "playerCatAnswerWrong"}>{currentQuestion.category}</div>
          <div className={currentPoints > 0 ? "playerAnswerCorrect" : "playerAnswerWrong"}>{currentQuestion.question}</div>


          {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}

        </div>
      )

      break
    default:
      break

  }



}
export default (FreeTextQuestion);