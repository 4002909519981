import React, { useState, useEffect } from 'react';
import Button from "./Button.js";
const questionState= 
{
    OPEN :1,
    CLOSED :2,
    SHOWANSWERS : 3,
    SHOWPOINTS : 4,    
}

function FreeNumberQuestion({currentQuestion,teamAnswers,currentQuestionState,ws}) {
  const [localactiveAnswer, setLocalActiveAnswer] = useState()  


  console.log("simple Question")
  //console.log("1:" + JSON.stringify(currentQuestion, null, 2));

  var questionStateText = "";

  const selectAnswer = (key) => {
    sendSelectAnswer(key)
  }


  function sendSelectAnswer(selectedAnswer) {
    
    const answerMsg = {"type": "PselectAnswer", "selectedAnswer": selectedAnswer, "questionID": currentQuestion.questionID}
    if (ws.readyState === WebSocket.OPEN) {
      console.log("send selection")
      ws.send(JSON.stringify(answerMsg));
    }
  }

  function submitAnswer() {
    //console.log(stopwatch.getElapsedStartedTime())
    const answerMsg = {"type": "Panswer","questionID": currentQuestion.questionID}
    if (ws.readyState === WebSocket.OPEN) {
      console.log("send answer")
      ws.send(JSON.stringify(answerMsg));
    }
  }

 
  var active =0;
  var questionStateText="";




  switch (currentQuestionState){
    case questionState.SHOWANSWERS:
       active =currentQuestion.correctAnswer;
       questionStateText="showanswer";
       break;
    case questionState.OPEN:
        active =0;
        questionStateText="open";
        break;
    case questionState.CLOSED:
      questionStateText="closed";
      break
      case questionState.SHOWPOINTS:
        questionStateText="points";
        break
    default:
        active=0;
        break

  }
  
  

switch (currentQuestionState) {
  case questionState.SHOWANSWERS:
    questionStateText = "showanswer";
    return (
      <div>
        
    
       <div className="activeAnswerquestionCatClosed">{currentQuestion.category}</div>
       <div className="questionClosed">{currentQuestion.question}</div>
        
        {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}
    
      </div>
    )


    break;
  case questionState.OPEN:
    
  questionStateText = "open";
    return (
      <div>
      <div className={currentQuestionState===1?"questionCat":"questionCatClosed"}>{currentQuestion.category}</div>
      <div className={currentQuestionState===1?"question":"questionClosed"}>{currentQuestion.question}</div>
  
       
  
    
      </div>
    )
    
    
    
    break;
  case questionState.CLOSED:
    questionStateText = "closed";
    return (
      <div>
       
       <div className="questionCatClosed">{currentQuestion.category}</div>
       <div className="questionClosed">{currentQuestion.question}</div>

       
        {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}
    
      </div>
    )

    break
  case questionState.SHOWPOINTS:
    questionStateText = "points";
    return (
      <div>
        
    
        <div className="questionCatClosed">{currentQuestion.category}</div>
       <div className="questionClosed">{currentQuestion.question}</div>
    
        
        
    
      </div>
    )
    
    break
  default:
    break

}


   
}
export default (FreeNumberQuestion);