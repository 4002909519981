import React from 'react';
import "./Led.css"



const Led = ({mode}) => {
  

  

  let ledClass="led-green"
  switch (mode){
    case 1:
      ledClass="led-green"
      break;
    case 2:
      ledClass="led-red"
      break;
    case 3:
      ledClass="led-yellow"
      break;
    case 4:
      ledClass="led-blue"
      break;
    default:
      ledClass="led-green"
      break;


  }

  return (
  
      <div className={ledClass}></div>
    
   
  
  );
};

export default Led;
