import React from 'react';
import Led from "./Led.js"


const Scoreboard = ({ teamsAndPlayers, teamAnswers }) => {



  const totalPoints = {}

  for (let index = 0; index < teamsAndPlayers.length; index++) {

    totalPoints[teamsAndPlayers[index].teamID] = 0
  }



  //lockedIn[key]=[true,answer.buzzerTime];

  //console.log("teamanswers" + teamAnswers);

  for (const key in teamAnswers) {
    if (Object.hasOwnProperty.call(teamAnswers, key)) {
      const team = teamAnswers[key];
      totalPoints[key] = team.totalPoints
      console.log("points")

    }
  }
  //const totalPoints_sort = [...totalPoints].sort((a, b) => b.totalPoints - a.totalPoints);

  var totalPoints_sort = Object.keys(totalPoints).map(
    (key) => { return [key, totalPoints[key]] });

  // Sort the array based on the second element (i.e. the value)
  totalPoints_sort.sort(
    (a, b) => { return b[1] - a[1] }
  );



  return (

    <div className='centerScoreboard'>
      <div class="mainScoreboard">
        <div id="header">
          <h1 className='room'>Leaderboard</h1>
        </div>

        <div id="leaderboard">
          <div class="ribbon"></div>
          <table border="0">

            {/*<tr><td class="number">Pos</td><td class="name">Teamname</td><td class="points">Points</td></tr>*/}

            {totalPoints_sort.map((team, index) =>


              <tr>

                <td class="number">{index + 1}</td><td class="name">{teamsAndPlayers[team[0]].teamName}</td><td class="points">{team[1]}</td>

              </tr>
            )

            }

          </table>
        </div>
      </div>
    </div>












  );
};

export default Scoreboard;