import React, { useState, useEffect } from 'react';
import Button from "./Button.js";




export default function GameStart({ ws }) {

  const startGame = () => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { type: "CstartGame" }

      ws.send(JSON.stringify(msg));
      console.log("msg")
    }
  }
  return (
    <div>
      <Button onClick={() => startGame()}>Start Game</Button>
    </div>

  )

}


export function RoundInput({roundNumberCallback, selectedQuestionID}) {
  return (
    <div className='controlerButtonAndInput'>
      R:<input className="controlerInput" type="number" pattern="[0-9]*" min="0" step="1" value={selectedQuestionID.R} onChange={roundNumberCallback} />
   </div>
  )
}

export function QuestionInput({ questionNumberCallback, selectedQuestionID }) {
  return (
    <div>
      Q:
      <input type="number" pattern="[0-9]*" value={selectedQuestionID.Q} onChange={questionNumberCallback} />
    </div>
  )
}



export function RoundStart({ ws,selectedQuestionID ,setCurrentRoundCallback}) {

  const startRound = (round) => {
    setCurrentRoundCallback(round)
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { "type": "CstartRound", "round": round }

      ws.send(JSON.stringify(msg));
      console.log("msg")
    }
  }
  return (
    <div className='controlerButtonAndInput'>
      <button className="controlerButton" onClick={() => startRound(selectedQuestionID.R)}>Start Round</button>
    </div>
  )
}



export function QuestionLoad({ ws, selectedQuestionID }) {
  const loadQuestion = (round, question) => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { "type": "CloadQuestion", "questionID": { "R": round, "Q": question } }
      ws.send(JSON.stringify(msg));
      console.log("msg")
    }
  }
  return (
    <div>
      <Button onClick={() => loadQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>LoadQuestion</Button>
    </div>
  )

}

export function RoundEnd({ws,selectedQuestionID}) {

  const endRound = (round) => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { "type": "CendRound", "round": round }

      ws.send(JSON.stringify(msg));
      console.log("msg")
    }
  }
  return (
    <div className='controlerButtonAndInput'>
      {/*<input className="controlerInput" type="number" pattern="[0-9]*" min="0" step="1" value={selectedQuestionID.R} onChange={roundNumberCallback} />*/}
      <button className="controlerButton" onClick={() => endRound(selectedQuestionID.R)}>End Round</button>
    </div>


  )

}

export function showScoreboard({ }) {

  return (
    <div></div>
  )

}



