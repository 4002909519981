import React from 'react';
import { useState, useEffect } from 'react';



const teamQuestionState =
{
  NOTLOCKEDIN: 1,
  LOCKEDIN: 2,
  CORRECT: 3,
  INCORRECT: 4,
}


const AnswerCorrect =
{
  NOTRATED: 1,
  INCORRECT: 2,
  CORRECT: 3,
}


const questiontype =
{
  MULTICHOICE: 1,
  NUMBERGUESS: 2,
  BUZZER: 3,
  FREETEXT: 4,
  FREENUMBER: 5,
  MAP: 6

}


const PointsManager = ({ ws, teamsAndPlayers, teamAnswers, currentQuestion, pointsForTeams }) => {



  const [givePointsToTeams, setgivePointsToTeams] = useState({})

  const setPoints = (event, key) => {
    setgivePointsToTeams(prevState => {
      return { ...prevState, [key]: parseInt(event.target.value) }
    })
  }

  


  var correctAnswerForPointManager = ""
  if (currentQuestion.questiontype === questiontype.MULTICHOICE) {//give correct option at multichoice
    correctAnswerForPointManager = currentQuestion.options[currentQuestion.correctAnswer] + " Option:" + currentQuestion.correctAnswer
  } else if (currentQuestion.questiontype === questiontype.MAP) {
    correctAnswerForPointManager = "top:" + currentQuestion.correctAnswer.top.toFixed(5) + " left:" + currentQuestion.correctAnswer.left.toFixed(5)
    //correctAnswerForPointManager=JSON.stringify(currentQuestion.correctAnswer)
  }
  else {
    correctAnswerForPointManager = currentQuestion.correctAnswer
  }


  const givePoints = (teamID, question, points) => {
    if (ws.readyState === WebSocket.OPEN) {
      var correct;
      if (points===undefined){
        points=question.points
      }
      if (points > 0) {
        correct = AnswerCorrect.CORRECT;
      } else {
        correct = AnswerCorrect.INCORRECT;
      }
      
      const msg = { "type": "CrateAnswers", "questionID": question.questionID, "teamID": parseInt(teamID), "correct": correct }
      ws.send(JSON.stringify(msg));
      const msg2 = { "type": "Cpoints", "questionID": question.questionID, "points": points, "teamID": parseInt(teamID) }
      ws.send(JSON.stringify(msg2));
      console.log("msg")
    }
  }




  const lockedIn = {}
  const totalPoints = {}

  for (let index = 0; index < teamsAndPlayers.length; index++) {
    lockedIn[teamsAndPlayers[index].teamID] = [teamQuestionState.NOTLOCKEDIN, "0.0", "teamsBarBubbles", "No Answer"]
    totalPoints[teamsAndPlayers[index].teamID] = 0
  }

  //lockedIn[key]=[true,answer.buzzerTime];

  //console.log("teamanswers" + teamAnswers);

  for (const key in teamAnswers) {

    if (Object.hasOwnProperty.call(teamAnswers, key)) {
      const answers = teamAnswers[key];
      //console.log("TEAM ANSWERs" + answer)
      const questionLockedIn = answers["lockedIn"]

      totalPoints[key] = answers["totalPoints"]
      for (const answerkey in answers["answers"]) {
        const answer = answers["answers"][answerkey]


        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q) {
          //anwer matches current Question
          lockedIn[key][0] = teamQuestionState.LOCKEDIN;
          lockedIn[key][1] = answer.buzzerTime.toFixed(3);
          lockedIn[key][2] = "teamsBarBubblesLockedin";


          if (!answers.lockedIn) {
            lockedIn[key][3] = "n/a"
          } else {
            if (currentQuestion.questiontype === questiontype.FREENUMBER) {
              const delta = parseInt(answer.answerData) - parseInt(currentQuestion.correctAnswer)
              lockedIn[key][3] = answer.answerData + "(" + delta + ")"
            } else if (currentQuestion.questiontype === questiontype.MAP && answer.answerData.length) {
              const distancey = (currentQuestion.correctAnswer.top - answer.answerData[0].top) * currentQuestion.correctAnswer.height * 0.01
              const distancex = (currentQuestion.correctAnswer.left - answer.answerData[0].left) * currentQuestion.correctAnswer.width * 0.01
              const distance = Math.sqrt(distancex * distancex + distancey * distancey)
              /*
              console.log("correcttop: " +currentQuestion.correctAnswer.top)
              console.log("answertop: " +answer.answerData[0].top)
              console.log("height: " + currentQuestion.correctAnswer.height)
              console.log("correcttop-answertop: "+(currentQuestion.correctAnswer.top-answer.answerData[0].top))
              console.log("(correcttop-answertop)*height: "+(currentQuestion.correctAnswer.top-answer.answerData[0].top)*currentQuestion.correctAnswer.height)
              console.log("disty:"+distancey)
              console.log("distx:"+distancex)
              console.log("dist:"+distance)*/

              lockedIn[key][3] = "top:" + answer.answerData[0].top.toFixed(3) + " left:" + answer.answerData[0].left.toFixed(3) + " dist:" + distance.toFixed(3)
            }
            else {
              lockedIn[key][3] = answer.answerData;
            }

          }





          //console.log("lockedIn")
        }
        if (answer.hasOwnProperty("rating")) {
          if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.rating === true) {
            //anwer matches current Question
            /*lockedIn[key][0] = teamQuestionState.CORRECT;
            lockedIn[key][1] = answer.buzzerTime.toFixed(3);*/
            lockedIn[key][2] = "teamsBarBubblesCorrect";
            //lockedIn[key][3] = answer.answerData;
          }
          if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.rating === false) {
            //anwer matches current Question
            /*lockedIn[key][0] = teamQuestionState.CORRECT;
            lockedIn[key][1] = answer.buzzerTime.toFixed(3);*/
            lockedIn[key][2] = "teamsBarBubblesWrong";
            //lockedIn[key][3] = answer.answerData;
          }
        }
      }

    }

  }
  for (const key in pointsForTeams) {
    if (Object.hasOwnProperty.call(pointsForTeams, key)) {
      const team = pointsForTeams[key];
      totalPoints[key] = team.totalPoints

    }


  }

  {/*<div className={lockedIn[key][2]}>*/ }
  return (
    <div>
      Correct Answer: {correctAnswerForPointManager}
      <div className="pointManagerGrid">
        {Object.entries(teamsAndPlayers).map(([key, value]) =>

          <div className='teamGrid'>
            <div>{value.teamName} </div>
            <div>{lockedIn[key][0] !== teamQuestionState.NOTLOCKEDIN ? "Answer:" + lockedIn[key][3] : "No Answer"}</div>
            <div>{lockedIn[key][0] !== teamQuestionState.NOTLOCKEDIN ? "Time:" + lockedIn[key][1] + "s" : "Not Locked in"}</div>
            <div> Total Points: {totalPoints[key]}</div>
            <div><input onChange={event => setPoints(event, key)} className="pointsInput" type="number" pattern="[0-9]*" min="0" step="1" defaultValue={currentQuestion.points} /></div>
            <div><button onClick={() => givePoints(key, currentQuestion, givePointsToTeams[key])}>Give Points</button></div>
            <div><button onClick={() => givePoints(key, currentQuestion, 0)}>0 Points</button></div>

          </div>



        )}
      </div>


    </div>
  );
};

export default PointsManager;