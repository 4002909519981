  import React from 'react';
  import AudioPlayer from './V_AudioPlayer';
  import VideoPlayer from './V_VideoPlayer';


  const AnswerCorrect=
  {
      NOTRATED :1,
      INCORRECT :2,
      CORRECT :3,
  }
  


  const questionState =
  {
    OPEN: 1,
    CLOSED: 2,
    SHOWANSWERS: 3,
    SHOWPOINTS: 4,
  }
  const mediatypeE=
{    
    TEXT: 1,
    AUDIO: 2,
    IMAGE: 3,
    VIDEO :4
}
  

const teamQuestionState =
{
  NOTLOCKEDIN: 1,
  LOCKEDIN: 2,
  CORRECT: 3,
  INCORRECT: 4,
}

  
  function FreeTextQuestion({ currentQuestion, currentQuestionState ,mediatype,mediafile,solutionMediatype ,solutionMediafile,teamsAndPlayers,teamAnswers}) {
  const lockedIn = {}
  var play=true
  

  for (let index = 0; index < teamsAndPlayers.length; index++) {
    lockedIn[teamsAndPlayers[index].teamID] = [teamQuestionState.NOTLOCKEDIN]
  }
  for (const key in teamAnswers) {

    if (Object.hasOwnProperty.call(teamAnswers, key)) {
      //all answers from Team "key"
      const answers = teamAnswers[key];
      const questionLockedIn=answers["lockedIn"]
      for (const answerkey in answers["answers"]) {
        const answer=answers["answers"][answerkey]
        //console.log(teamAnswers)
        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q){
          console.log("stop")
        }
        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && questionLockedIn && answer.answeredCorrectly===AnswerCorrect.NOTRATED &&answers["lockedIn"]) {
          //anwer matches current Question
          lockedIn[key][0] = teamQuestionState.LOCKEDIN;
        }
        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.answeredCorrectly===AnswerCorrect.CORRECT && currentQuestionState>questionState.CLOSED ) {
          //anwer matches current Question
          lockedIn[key][0] = teamQuestionState.CORRECT;
        }
        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.answeredCorrectly === AnswerCorrect.INCORRECT && currentQuestionState>questionState.CLOSED ) {
          //anwer matches current Question
          lockedIn[key][0] = teamQuestionState.INCORRECT;
        }

      }
    }
  }
    
    
    
    
    console.log("Freetext Question")
    var active = 0;
   
    switch (currentQuestionState) {
      case questionState.SHOWANSWERS:
        active = currentQuestion.correctAnswer;
        play=false
        break;
      case questionState.OPEN:
        
      //stop video when locked in
      for (let index = 0; index < Object.keys(lockedIn).length; index++) {
          if (lockedIn[index][0]===teamQuestionState.LOCKEDIN){
            play=false
          }else{

          }
        }
        active = 0;
        break;
      case questionState.CLOSED:
        play=false
        break
      case questionState.SHOWPOINTS:
        break
      default:
        active = 0;
        break
    }
    var src=""
    if (mediatype===mediatypeE.IMAGE){
       src=`data:image/jpeg;base64,${mediafile}`
    }

    return (
      <div>
      
      <div className={currentQuestionState === 1 ? "questionCat" : "questionCatClosed"}>{currentQuestion.category}</div>
      <div className={currentQuestionState === 1 ? "question" : "questionClosed"}>{currentQuestion.question}</div>
      {currentQuestionState >=3 ?<div className="solution" >{currentQuestion.correctAnswer}</div>:<div></div>}
      <div className='mediaWindow'>
      {mediatype === mediatypeE.IMAGE ? <div className="image">{currentQuestionState <= 2 ?<img src={`data:image/jpeg;base64,${mediafile}`}  height="400" />:""}</div> : ""}
      {mediatype === mediatypeE.AUDIO ? <div className="image">{currentQuestionState <= 1 ? <AudioPlayer mediafile={mediafile} play={play}/> : ""}</div> : ""}
      {mediatype === mediatypeE.VIDEO ? <div className="image">{currentQuestionState <= 2 ? <VideoPlayer mediafile={currentQuestion.mediafile} play={play}/> : ""}</div> : ""}
      

     
      {currentQuestion.solution.solutionType === mediatypeE.IMAGE ? <div className="image">{currentQuestionState > 2 ?<img src={`data:image/jpeg;base64,${solutionMediafile}`} height="400" />:""}</div> : ""}
      {currentQuestion.solution.solutionType === mediatypeE.AUDIO ? <div className="image">{currentQuestionState === 3 ? <AudioPlayer mediafile={solutionMediafile} play={true}/> : ""}</div> : ""}
      {currentQuestion.solution.solutionType === mediatypeE.VIDEO ? <div className="image">{currentQuestionState > 2 ? <VideoPlayer mediafile={currentQuestion.solution.solutionMediafile} play={true}/> : ""}</div> : ""}
      {currentQuestion.solution.solutionType === mediatypeE.TEXT ? <div className="question">{currentQuestionState > 2 ? currentQuestion.solution.solutionText : ""}</div> : ""}
      </div>

      
      </div>
    )
  

   
}
export default (FreeTextQuestion);