
import React, { useState, useEffect } from 'react';
import Button from "./Button.js";
import { useStopwatch } from "react-use-precision-timer";


const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}




function WhoKnowsMoreQuestion({ currentQuestion}) {


  return (
    <div>
      <div className="questionCat">{currentQuestion.category}</div>
      <div className="question">{currentQuestion.question}</div>

      <div className='questionClosed'>
        Look at the big screen
      </div>

    </div>
  )




}

export default (WhoKnowsMoreQuestion);