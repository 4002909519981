import React, { useState, useEffect } from 'react';
import ImageMarker from 'react-image-marker';


const AnswerCorrect =
{
  NOTRATED: 1,
  INCORRECT: 2,
  CORRECT: 3,
}



const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}
const mediatypeE =
{
  TEXT: 1,
  AUDIO: 2,
  IMAGE: 3,
  VIDEO: 4
}


const teamQuestionState =
{
  NOTLOCKEDIN: 1,
  LOCKEDIN: 2,
  CORRECT: 3,
  INCORRECT: 4,
}





function MapQuestion({ currentQuestion, currentQuestionState, mediatype, mediafile, solutionMediatype, solutionMediafile, teamsAndPlayers, teamAnswers }) {
  const [allTeamsMarker, setAllTeamsMarker] = useState({})
  const [allTeamsMarkerArray, setAllTeamsMarkerArray] = useState([])


  var i =0;
  const CustomMarker = () => {
    var teamName;
    const teamId = Object.keys(allTeamsMarker)[i]
    if (teamId==="correct"){
      teamName="CORRECT"  
    }else{
      teamName=teamsAndPlayers[teamId].teamName
    }
    i++
    //console.log(name)
    return (
      <div> {teamName==="CORRECT"?<div className="viewerMarkertextCorrect">{teamName} </div>:<div className="viewerMarkertext">{teamName} </div>}
        {teamName==="CORRECT"? <div className="viewerMarkerCorrect" data-testid="marker" ></div>: <div className="viewerMarker" data-testid="marker"></div> }
        
      </div>
    );
  };




  useEffect(() => {
    for (const key in teamAnswers) {
      if (Object.hasOwnProperty.call(teamAnswers, key)) {
        //all answers from Team "key"
        const answers = teamAnswers[key];
        for (const answerkey in answers["answers"]) {
          const answer = answers["answers"][answerkey]
          if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q) {
            const coordinates = answer.answerData
            if (coordinates !== -1) {
              setAllTeamsMarker(allTeamsMarker => ({...allTeamsMarker, [key]:coordinates}))
             
            }
          }
        }
      }
    }
    //console.log(allTeamsMarker)
  }, [teamAnswers]);

  useEffect(() => {
    var teamMarkers = []
    if (Object.keys(allTeamsMarker).length > 0) {
      {
        Object.entries(allTeamsMarker).map(([key, value]) => {
          //<div>Suggestion from {key}: {value.selectedAnswer}<br /></div>
            if (allTeamsMarker[key].length)
              teamMarkers.push(allTeamsMarker[key][0])
          }
        )
      }
    }
    setAllTeamsMarkerArray(teamMarkers)
  }, [allTeamsMarker]);


  const lockedIn = {}

  for (let index = 0; index < teamsAndPlayers.length; index++) {
    lockedIn[teamsAndPlayers[index].teamID] = [teamQuestionState.NOTLOCKEDIN]
  }
  for (const key in teamAnswers) {

    if (Object.hasOwnProperty.call(teamAnswers, key)) {
      //all answers from Team "key"
      const answers = teamAnswers[key];
      const questionLockedIn = answers["lockedIn"]
      for (const answerkey in answers["answers"]) {
        const answer = answers["answers"][answerkey]
        //console.log(teamAnswers)
        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q) {

        }
        //console.log("stop")
      
      if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && questionLockedIn && answer.answeredCorrectly === AnswerCorrect.NOTRATED && answers["lockedIn"]) {
        //anwer matches current Question
        lockedIn[key][0] = teamQuestionState.LOCKEDIN;
      }
      if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.answeredCorrectly === AnswerCorrect.CORRECT && currentQuestionState > questionState.CLOSED) {
        //anwer matches current Question
        lockedIn[key][0] = teamQuestionState.CORRECT;
      }
      if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.answeredCorrectly === AnswerCorrect.INCORRECT && currentQuestionState > questionState.CLOSED) {
        //anwer matches current Question
        lockedIn[key][0] = teamQuestionState.INCORRECT;
      }

    }
  }
}




console.log("Freetext Question")
var active = 0;

switch (currentQuestionState) {
  case questionState.SHOWANSWERS:
    active = currentQuestion.correctAnswer;
    if (!allTeamsMarker.hasOwnProperty("correct")){
      setAllTeamsMarker(allTeamsMarker => ({...allTeamsMarker, ["correct"]:[currentQuestion.correctAnswer]}))
    }
   
    break;
  case questionState.OPEN:

    //stop video when locked in
    for (let index = 0; index < Object.keys(lockedIn).length; index++) {
      if (lockedIn[index][0] === teamQuestionState.LOCKEDIN) {
      
      } else {

      }
    }
    active = 0;
    break;
  case questionState.CLOSED:
   
    break
  case questionState.SHOWPOINTS:
    break
  default:
    active = 0;
    break
}
return (
  <div className='mediaWindow'>

    <div className={currentQuestionState === 1 ? "questionCat" : "questionCatClosed"}>{currentQuestion.category}</div>
    <div className={currentQuestionState === 1 ? "question" : "questionClosed"}>{currentQuestion.question}</div>
    
      <div width={"30px"}>
      {currentQuestionState > 1?
      <ImageMarker
        src={`data:image/jpeg;base64,${mediafile}`}
        markers={allTeamsMarkerArray}
        onAddMarker={()=>(console.log(""))}
        markerComponent={CustomMarker}
/>:""}
</div>


  </div>
)
  

   
}
export default (MapQuestion);