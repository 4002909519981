function SongQuestion({currentQuestion}) {
   
   
    

        return(
          
        <div>
          <h1>show Question</h1>
          
          Round N:{currentQuestion.questionID.R}<br />
          Question N: {currentQuestion.questionID.Q}<br />
          Category: {currentQuestion.category}<br />
          Points: {currentQuestion.points}<br />
          Question: {currentQuestion.question}<br />
          Options:
          {Object.entries(currentQuestion.options).map(([key, value]) =>
            <li>{value[1]} </li>
          )}

        </div>)
   
}
export default (SongQuestion);