import React, { useState, useEffect } from 'react';
import Button from "./Button.js";
import { useStopwatch } from "react-use-precision-timer";


const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}




function SimpleQuestion({ currentQuestion, syncAnswer, currentQuestionState,ws,currentPoints }) {
  const [localactiveAnswer, setLocalActiveAnswer] = useState()  
  const stopwatch = useStopwatch();

  console.log("simple Question")
  //console.log("1:" + JSON.stringify(currentQuestion, null, 2));
  console.log("syncAnswer:" + JSON.stringify(syncAnswer, null, 2))

  
  var activeAnswer = syncAnswer.selectedAnswer;
  var lockedIn = syncAnswer.lockedIn;
  var questionStateText = "";

  const selectAnswer = (key) => {
    sendSelectAnswer(key)
  }


  function sendSelectAnswer(selectedAnswer) {
    
    const answerMsg = {"type": "PselectAnswer", "selectedAnswer": selectedAnswer, "questionID": currentQuestion.questionID}
    if (ws.readyState === WebSocket.OPEN && lockedIn===false) {
      console.log("send selection")
      ws.send(JSON.stringify(answerMsg));
    }
  }

  function submitAnswer() {
    //console.log(stopwatch.getElapsedStartedTime())
    const answerMsg = {"type": "Panswer","questionID": currentQuestion.questionID,"buzzerTime": stopwatch.getElapsedStartedTime()/1000, "answerData": activeAnswer}
    if (ws.readyState === WebSocket.OPEN) {
      console.log("send answer")
      ws.send(JSON.stringify(answerMsg));
    }
  }

  useEffect(() => {
    if (currentQuestionState === questionState.OPEN) {
      console.log("stopwatch started")
      stopwatch.start()
    }
  
  }, [currentQuestionState]);





  var active =0;
  var questionStateText="";


  switch (currentQuestionState){
    case questionState.SHOWANSWERS:
       active =currentQuestion.correctAnswer;
       questionStateText="showanswer";
       break;
    case questionState.OPEN:
        active =0;
        questionStateText="open";
        break;
    case questionState.CLOSED:
      questionStateText="closed";
      break
      case questionState.SHOWPOINTS:
        questionStateText="points";
        break
    default:
        active=0;
        break

  }


/*
  return(
    <div>    
    <div className={currentQuestionState===1?"questionCat":"questionCatClosed"}>{currentQuestion.category}</div>
    <div className={currentQuestionState===1?"question":"questionClosed"}>{currentQuestion.question}</div>

      
    {Object.entries(currentQuestion.options).map(([key, value]) =>
      <div>
      <Button onClick={() => selectAnswer(parseInt(key))} style={{ backgroundColor: (activeAnswer === (parseInt(key))) ? "RoyalBlue" : "navy" }}>{currentQuestion.options[key]}</Button>
      </div>
    )}
    <Button onClick={() => submitAnswer()} style={{ backgroundColor: (lockedIn) ? "gray" : "#b55400" }}>Submit</Button>
   </div>
    )
*/
switch (currentQuestionState) {
  case questionState.SHOWANSWERS:
    questionStateText = "showanswer";
    return (
      <div>
       <div className="playerQuestionCatClosed">{currentQuestion.category}</div>
       <div className="playerQuestionClosed">{currentQuestion.question}</div>
        {Object.entries(currentQuestion.options).map(([key, value]) =>
           <button className= {(currentQuestion.correctAnswer === (parseInt(key))) ? "playerMultiButtonCorrect playerMultiButtonGeneric" : (activeAnswer === (parseInt(key))) ? "playerMultiButtonWrong playerMultiButtonGeneric" : "playerSubmitButtonLockedin playerMultiButtonGeneric"}>{currentQuestion.options[key]}</button>
        )}
        {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}
    
      </div>
    )


    break;
  case questionState.OPEN:
    
  questionStateText = "open";
    return (
      <div>
      <div className={currentQuestionState===1?"playerQuestionCat":"playerQuestionCatClosed"}>{currentQuestion.category}</div>
      <div className={currentQuestionState===1?"playerQuestion":"playerQuestionClosed"}>{currentQuestion.question}</div>

        
        {Object.entries(currentQuestion.options).map(([key, value]) =>
          <button onClick={() => selectAnswer(parseInt(key))} className={activeAnswer === (parseInt(key)) ? "playerMultiButton playerMultiButtonGeneric" : "playerMultiButtonSelected playerMultiButtonGeneric" }>{currentQuestion.options[key]}</button>
        )}
        <button onClick={() => submitAnswer()} className={(lockedIn) ? "playerSubmitButtonLockedin playerMultiButtonGeneric" : "playerSubmitButton playerMultiButtonGeneric" }>Submit</button>
    
      </div>
    )
    
    
    
    break;
  case questionState.CLOSED:
    questionStateText = "closed";
    return (
      <div>
       
       <div className="playerQuestionCatClosed">{currentQuestion.category}</div>
       <div className="playerQuestionClosed">{currentQuestion.question}</div>

        {Object.entries(currentQuestion.options).map(([key, value]) =>
            <button onClick={() => selectAnswer(parseInt(key))} className={activeAnswer === (parseInt(key)) ? "playerMultiButton playerMultiButtonGeneric" : "playerMultiButtonSelected playerMultiButtonGeneric" }>{currentQuestion.options[key]}</button>
        )}
        
        {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}
    
      </div>
    )

    break
  case questionState.SHOWPOINTS:
    questionStateText = "points";
    return (
      <div>
        
    
        <div className={currentPoints>0?"playerCatAnswerCorrect":"playerCatAnswerWrong"}>{currentQuestion.category}</div>
        <div className={currentPoints>0?"playerAnswerCorrect":"playerAnswerWrong"}>{currentQuestion.question}</div>
    
        
        {Object.entries(currentQuestion.options).map(([key, value]) =>
            <Button style={{ backgroundColor: (currentQuestion.correctAnswer === (parseInt(key))) ? "green" : (activeAnswer === (parseInt(key))) ? "navy" : "grey"}}>{currentQuestion.options[key]}</Button>
        )}
        {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}
    
      </div>
    )
    
    break
  default:
    break

}


   
}
export default (SimpleQuestion);