import React, { useState, useEffect } from 'react';
//import Button from "./Button.js";
const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}


function WhoKnowsMoreQuestion({ currentQuestion, teamAnswers, currentQuestionState, ws ,selectedAnswers}) {



  //console.log("1:" + JSON.stringify(currentQuestion, null, 2));

  var questionStateText = "";


  var active = 0;
  var questionStateText = "";

  var activeAnswer=-1;

  function submitAnswer() {
    //console.log(stopwatch.getElapsedStartedTime())
    const answerMsg = {"type": "CSelectedAnswer","questionID": currentQuestion.questionID, "answerData": activeAnswer}
    if (ws.readyState === WebSocket.OPEN) {
      console.log("send answer")
      ws.send(JSON.stringify(answerMsg));
    }
  }


  function selectAnswer(selectedAnswer) {
    selectedAnswers.push(selectedAnswer)
    console.log("select answer"+ selectedAnswer)
    activeAnswer=selectedAnswer;
  }

  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:
      active = currentQuestion.correctAnswer;
      questionStateText = "showanswer";
      break;
    case questionState.OPEN:
      active = 0;
      questionStateText = "open";
      break;
    case questionState.CLOSED:
      questionStateText = "closed";
      break
    case questionState.SHOWPOINTS:
      questionStateText = "points";
      break
    default:
      active = 0;
      break

  }



  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:
      questionStateText = "showanswer";
      return (
        <div>


          <div className="activeAnswerquestionCatClosed">{currentQuestion.category}</div>
          <div className="questionClosed">{currentQuestion.question}</div>
          {Object.entries(currentQuestion.options).map(([key, value]) =>
            <button style={{ backgroundColor: "grey" }}>{currentQuestion.options[key]}</button>
          )}
          {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}

        </div>
      )


      break;
    case questionState.OPEN:

      questionStateText = "open";
      return (
        <div>
          <div className={currentQuestionState === 1 ? "questionCat" : "questionCatClosed"}>{currentQuestion.category}</div>
          <div className={currentQuestionState === 1 ? "question" : "questionClosed"}>{currentQuestion.question}</div>
          <div className="viewerMultiAnswerGrid">
          {Object.entries(currentQuestion.options).map(([key, value]) =>
                  <div className='viewerMultiAnswerGridItem'>

            <button onClick={() => selectAnswer(parseInt(key))} className={selectedAnswers.includes(parseInt(key)) ? "answerButton answerButton" : "answerButtonPending answerButton"}>{currentQuestion.options[key]}</button>
            </div>

          )}
          </div>
          <button onClick={() => submitAnswer()} className={"playerSubmitButton playerMultiButtonGeneric"}>Submit</button>


        </div>
      )



      break;
    case questionState.CLOSED:
      questionStateText = "closed";
      return (
        <div>

          <div className="questionCatClosed">{currentQuestion.category}</div>
          <div className="questionClosed">{currentQuestion.question}</div>

          {Object.entries(currentQuestion.options).map(([key, value]) =>
            <button >{currentQuestion.options[key]}</button>
          )}

          {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}

        </div>
      )

      break
    case questionState.SHOWPOINTS:
      questionStateText = "points";
      return (
        <div>


          <div className="questionCatClosed">{currentQuestion.category}</div>
          <div className="questionClosed">{currentQuestion.question}</div>


          {Object.entries(currentQuestion.options).map(([key, value]) =>
            <button >{currentQuestion.options[key]}</button>
          )}
          {/*<Button style={{ backgroundColor: "grey" }}>Submit</Button>*/}

        </div>
      )

      break
    default:
      break

  }



}
export default (WhoKnowsMoreQuestion);