import React, { useState, useEffect,useRef,memo } from 'react';
import AudioPlayer from './V_AudioPlayer';
import VideoPlayer from './V_VideoPlayer';

const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}

const mediatypeE =
{
  TEXT: 1,
  AUDIO: 2,
  IMAGE: 3,
  VIDEO: 4
}


 
function SimpleQuestion({ currentQuestion, currentQuestionState, mediatype, mediafile,solutionMediatype ,solutionMediafile}) {
  
//console.log(currentQuestionState)

  
 
  var play=true
  var active = 0;
 switch (currentQuestionState) {
    case questionState.SHOWANSWERS:
      active = currentQuestion.correctAnswer;
      play=false
      break;
    case questionState.OPEN:
      play=true
      active = 0;
      break;
    case questionState.CLOSED:
      play=false
      break
    case questionState.SHOWPOINTS:
      break
    default:
      active = 0;
      break
  }

  
   return (
    
    <div className="viewerQuestionContent"> 
      <div className={currentQuestionState === 1 ? "questionCat" : "questionCatClosed"}>{currentQuestion.category}</div>
      <div className={currentQuestionState === 1 ? "question" : "questionClosed"}>{currentQuestion.question}</div>
      
      
      {mediatype === mediatypeE.IMAGE ? <div className="image">{currentQuestionState <= 2 ?<img src={`data:image/jpeg;base64,${mediafile}`}  height="400" />:""}</div> : ""}
      {mediatype === mediatypeE.AUDIO ? <div className="image">{currentQuestionState <= 1 ? <AudioPlayer mediafile={mediafile} play={play}/> : ""}</div> : ""}
      {mediatype === mediatypeE.VIDEO ? <div className="image">{currentQuestionState <= 2 ? <VideoPlayer mediafile={currentQuestion.mediafile} play={play}/> : ""}</div> : ""}
      

     
      {currentQuestion.solution.solutionType === mediatypeE.IMAGE ? <div className="image">{currentQuestionState > 2 ?<img src={`data:image/jpeg;base64,${solutionMediafile}`} height="400" />:""}</div> : ""}
      {currentQuestion.solution.solutionType === mediatypeE.AUDIO ? <div className="image">{currentQuestionState === 3 ? <AudioPlayer mediafile={solutionMediafile} play={true}/> : ""}</div> : ""}
      {currentQuestion.solution.solutionType === mediatypeE.VIDEO ? <div className="image">{currentQuestionState > 2 ? <VideoPlayer mediafile={currentQuestion.solution.solutionMediafile} play={true}/> : ""}</div> : ""}
      {currentQuestion.solution.solutionType === mediatypeE.TEXT ? <div className="question">{currentQuestionState > 2 ? currentQuestion.solution.solutionText : ""}</div> : ""}
      
      <div className={currentQuestionState <= 2 ? "viewerMultiAnswerGrid" : "viewerMultiAnswerGridSolution"}>
      {Object.entries(currentQuestion.options).map(([key, value]) =>
        <div className='viewerMultiAnswerGridItem'>
          <button key={key} className={(active === (parseInt(key)) && currentQuestionState > 2) ? "answerButtonCorrect answerButton" : "answerButtonPending answerButton"}>{currentQuestion.options[key]}</button>
        </div>
        
      )}
      </div>
    </div>
  )

}
export default (SimpleQuestion);