import React, { useState, useEffect } from 'react';


function QuestionInfoBar({currentQuestion}) {
      
         return(
            <div className="questionInfo">
            <div>Round: {currentQuestion.questionID.R}</div>
            <div> Question: {currentQuestion.questionID.Q+1} </div>
            <div> Points: {currentQuestion.points}</div>
            </div>
          )
   
}
export default (QuestionInfoBar);