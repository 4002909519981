import React, { useState, useEffect, useRef } from 'react';




const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}

const mediatypeE =
{
  TEXT: 1,
  AUDIO: 2,
  IMAGE: 3,
  VIDEO: 4
}
function start(audio) {
  audio.play()
}
function stop(audio) {
  audio.pause()
}









function AudioPlayer({ mediafile,play}) {
  
  const [audioPlayer, setAudioPlayer] = useState()

  
  if (mediafile!==undefined && mediafile!==""){
    if (audioPlayer===undefined){
      setAudioPlayer(new Audio(`data:audio/mp3;base64,${mediafile}`))
    }else{
    
    if (play){
      start(audioPlayer)  
    }else{
      stop(audioPlayer)
    }
  }
  }
  
  

return (
  <div>
    {play?<img src='music.gif' width={"100%"}/>:<img src='musicstop.jpeg' width={"100%"}/>}
  </div>
)

}

//const MemoizedAudioPlayer = React.memo(AudioPlayer);
export default (AudioPlayer);