import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Viewer from "./Viewer.js";
import Controler from "./Controler.js";
import Player from "./Player.js";

function App() {

  

  return (
  
    <Router>
      <Routes>
        <Route path="/Viewer" element={<Viewer />}></Route>
        <Route path="/Controler" element={<Controler />}></Route>
        <Route path="/" element={<Player />}></Route>
      </Routes>
    </Router>  
 
     
     
      
   
  );
   {/*crashed && <RecoverButton />*/}
      {/*popUpActive && <PopUp />*/}
}

export default App;