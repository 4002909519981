import React, { useState, useEffect } from 'react';
import './style/index.scss';
import SimpleQuestion from "./V_SimpleQuestion.js";
import BuzzerQuestion from './V_BuzzerQuestion.js';
import SongQuestion from "./V_SongQuestion.js";
//import MapQuestion from "./V_MapQuestion_old.js";
import GuessQuestion from "./V_GuessQuestion.js";
import Loader from "./Loader.js";
import TeamsBar from "./V_TeamsBar.js";
import Scoreboard from "./V_Scoreboard.js";
import Button from "./Button.js";
import QuestionInfoBar from "./V_QuestionInfoBar.js";
import FreeTextQuestion from './V_FreeTextQuestion.js';
import FreeNumberQuestion from './V_FreeNumberQuestion.js';
import MapQuestion from './V_MapQuestion.js';
import WhoKnowsMoreQuestion from './V_WhoKnowsMoreQuestion.js';
import configData from "./config.json";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { StyleSheet, View, Text } from 'react-native';

const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}

//change in player,viewer,controler
const questiontype =
{
  MULTICHOICE: 1,
  NUMBERGUESS: 2,
  BUZZER: 3,
  FREETEXT: 4,
  FREENUMBER: 5,
  MAP: 6,
  WHOKNOWSMORE:7,

}


const mediatype =
{
  TEXT: 1,
  AUDIO: 2,
  IMAGE: 3,
  VIDEO: 4
}



const viewMode =
{
  DISCONNECTED: 0,
  LOBBY: 1,
  SUMMARY: 2,
  LOADQUESTION: 3,
  PREQUESTION: 4,
  QUESTION: 5,
  ANSWER: 6,
  STARTROUND: 7,
  ENDROUND: 8,
  SCOREBORD: 9
}


const connection = {
  connected: 1,
  connectedAndJoined: 2,
  disconnected: 3,
}


const mystyles = StyleSheet.create({
  welcome: {
    fontSize: RFValue(24, 580), // second argument is standardScreenHeight(optional),
    textAlign: "center",
    margin: 10,
  },
  instructions: {
    textAlign: "center",
    color: "#333333",
    marginBottom: 5,
    fontSize: RFPercentage(5),
  },
});



function Viewer() {
  //console.log("Viewer Render");

  //const [message, setMessage] = useState("");
  //const [inputValue, setInputValue] = useState('');
  const [ws, setWs] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(connection.disconnected);
  //const [received, setreceived] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState()
  const [currentRound, setCurrentRound] = useState()
  const [currentQuestionState, setCurrentQuestionState] = useState(questionState.OPEN)


  //const [gameJoinStatus, setgameJoinStatus] = useState('notJoined');
  const [view2data, setView2data] = useState();
  const [viewerMode, setViewerMode] = useState(0);


  const [teamAndPlayers, setTeamAndPlayers] = useState();
  const [answersFromTeams, setAnswerFromTeams] = useState()
  //const [pointsForTeams,setPointsForTeams]=useState()

  const [flashName, setFlashName] = useState([])

  const [mediafile, setMediaFile] = useState();
  const [solutionMediafile, setSolutionMediaFile] = useState();

  const [selectedAnswers, setSelectedAnswers] = useState([])


  const handleConnect = () => {
    var url = ""
    if (configData.socketssl) {
      url = "wss://" + configData.serverURL + ":" + configData.serverPort
    } else {
      url = "ws://" + configData.serverURL + ":" + configData.serverPort
    }
    const newWs = new WebSocket(url);
    newWs.onopen = () => {
      ////console.log("onOpen")
      setConnectionStatus(connection.connected);
    };

    newWs.onclose = () => {
      setConnectionStatus(connection.disconnected);
    };


    newWs.onmessage = event => {

      const receivedMessage = JSON.parse(event.data);

      ////console.log("type:" + receivedMessage.type)
      ////console.log(JSON.stringify(receivedMessage, null, 2))

      switch (receivedMessage.type) {
        case "SGameConfig":
          ////console.log(JSON.stringify(view2data, null, 2))
          setView2data(receivedMessage);
          break
        case "mode":
          setViewerMode(receivedMessage.mode);
          //console.log("received Mode");
          break
        case "updatePlayers":
          setTeamAndPlayers(receivedMessage);
          break

        case "SloadQuestion":
          setCurrentQuestion(receivedMessage.question)
          setCurrentQuestionState(questionState.OPEN)
          break

        case "ScloseQuestion":
          setCurrentQuestionState(questionState.CLOSED)
          break

        case "SshowAnswer":
          setCurrentQuestionState(questionState.SHOWANSWERS)
          break
        /*
        case "SteamAnswer":
          
        setAnswerFromTeams(prevState => {
            return { ...prevState, [receivedMessage.teamID]: receivedMessage.answer };
          });
          break*/

        case "SallTeamAnswers":
          setAnswerFromTeams(prevState => {
            return { ...prevState, [receivedMessage.teamID]: { ["answers"]: receivedMessage.answers, ["totalPoints"]: receivedMessage.totalPoints, ["lockedIn"]: receivedMessage.lockedIn } }
          });

          break

        case "SstartGame":
          setViewerMode(viewMode.SUMMARY)
          break

        case "SstartRound":
          setCurrentRound(receivedMessage.round)
          setCurrentQuestionState(questionState.STARTROUND)
          setViewerMode(viewMode.STARTROUND)
          break

        case "SendRound":
          setCurrentQuestionState(questionState.ENDROUND)
          break

        case "SrateAnswer":
          setAnswerFromTeams(prevState => {
            prevState[receivedMessage.teamID].rating = receivedMessage.correct;
            return { ...prevState, [receivedMessage.teamID]: prevState[receivedMessage.teamID] }
          });
          break

        case "SflashPlayer":
          setFlashName(prevState => [...prevState, receivedMessage.playerName])
          break;

        case "img":
          setMediaFile(receivedMessage.img)
          break;
        case "audio":
          setMediaFile(receivedMessage.mp3)
          break;

        case "audioSol":
          setSolutionMediaFile(receivedMessage.mp3)
          break;
        case "imgSol":
          setSolutionMediaFile(receivedMessage.img)
          break;
        case "SselectedAnswers":
          setSelectedAnswers(receivedMessage.selectedAnswers)
          break






        default:

          break

      }
      //setreceived(true)



    };
    setWs(newWs);

  };

  useEffect(() => {
    if (ws) {
      return () => {
        ws.close();
      };
    }

  }, [ws]);

  useEffect(() => {
    if (connectionStatus === connection.connected) {
      //console.log("Connection connected")
      handleJoinGame();

    }

  }, [connectionStatus]);



  const removeFlasher = value => {
    setFlashName(prevState => {
      return prevState.filter(name => name !== value)
    })
  }


  useEffect(() => {
    setTimeout(() => {

      if (flashName.length !== 0) {
        setFlashName(prevState => [...new Set(prevState)]);
        removeFlasher(flashName.pop())
      }


    }, 3000);


  }, [flashName]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }


  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }





  const handleJoinGame = () => {
    if (ws.readyState === WebSocket.OPEN) {
      const joinGameMsg = { type: "VjoinGame" }
      setConnectionStatus(connection.connectedAndJoined)
      ws.send(JSON.stringify(joinGameMsg));
      //console.log("joinGame")
    }
  }
  // console.log(connectionStatus)
  //if (connectionStatus === connection.connected) {
  //  console.log("handleSendMessage")
  //handleSendMessage();
  //}
  const { height, width } = useWindowDimensions();

  switch (viewerMode) {
    case viewMode.DISCONNECTED: //not connected 
      return (
        <div>
          <h1>Viewer</h1>
          <div>

            <Button onClick={handleConnect} disabled={connectionStatus === connection.connected}>
              {connectionStatus === connection.connected ? 'Connected!' : 'Connect'}
            </Button>
            <div>{connectionStatus === connection.connected ? 'Connected to WebSocket server' : 'Not connected to WebSocket server'}</div>
          </div>

        </div>
      );
    //break;
    case viewMode.LOBBY: //show all teams, lobby
      if (teamAndPlayers !== undefined) {
        return (
          <div className='centerLobby'>
            <main className="mainLobby">
              <div id="header">
                <h1 className='room'>Lobby</h1>
              </div>
              <div id="lobbyboard">
                <div className="lobbyBoard">
                  {Object.entries(teamAndPlayers.teams).map(([key, value]) =>
                    <div className="lobbyTeamBubble">
                      <div className="teamNameLobby">{value.teamName} </div>
                      {Object.entries(value.teammember).map((player) =>
                        <li className={flashName.includes(player[1]) ? "blinkLobby" : "viewerLobbyPlayerName"}>{player[1]}</li>
                      )

                      }
                    </div>

                  )}
                </div>
                <h1 className='url'>{configData.serverURL}</h1>
              </div>
              
            </main>
           
          </div>
        )
      } else {
        return (
          <div>
            <h1>Viewer</h1>
            No Player data received
          </div>
        )
      }
    //break;

    case viewMode.SUMMARY: //show categories

      var rounds = []
      if (view2data !== undefined) {
        for (var r in view2data.gameConfig) {
          if (view2data.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            rounds.push(view2data.gameConfig[r])
          }
        }
        return (
          <div className="mainLobby">
            <div id="header">
              <h1 className='room'>Categories</h1>
            </div>


            {Object.entries(rounds).map(([key, value]) =>
              key !== "0" ? <div className="catbubbleOverview"><div className='teamNameLobby'>Round {value.roundID} </div>Category: {value.category}<br />Questions: {value.nQuestions}<br /></div> : <div />

            )}

          </div>

        )
      }
      break;
    case viewMode.LOADQUESTION:
      //console.log("Loading ...")
      return (
        <div className="infoBoard">
          <div className="infoBox">
            <div className='room'>
              Loading Question
            </div>
            <Loader />
          </div>
        </div>
      )

    case viewMode.PREQUESTION: // Prequestion (load Question)
      if (currentQuestion !== undefined) {
        return (


          <div className="infoBoard">
            <div className="infoBox">
              <div className="preQround">Round: {currentQuestion.questionID.R}</div>
              <div className="preQquestion">
                Question {currentQuestion.questionID.Q + 1}
              </div>
              <div className="catbubble">
                Points: {currentQuestion.points}<br />
                Category: {currentQuestion.category}
              </div>
            </div>
          </div>
        )
      } else {
        return (

          <div className="infoBoard">
            <div className="infoBox">
              <div className='room'>
                Loading Question
              </div>
              <Loader />
            </div>
          </div>

        )
      }


    case viewMode.ANSWER:
    case viewMode.QUESTION: //show question
      if (currentQuestion !== undefined) {
        switch (currentQuestion.questiontype) {
          case questiontype.MULTICHOICE:
            ////console.log("Switch Case" + JSON.stringify(answersFromTeams, null, 2));
            //console.log("Viewer Multiquestion")
            return (
              <div>
                <div style={{ height: "20VH" }}>
                  <TeamsBar teamsAndPlayers={teamAndPlayers.teams} currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                </div>
                <div style={{ height: "5VH" }}>
                  <QuestionInfoBar currentQuestion={currentQuestion} />
                </div>
                <div style={{ height: "74VH" }}>
                  <SimpleQuestion currentQuestion={currentQuestion} currentQuestionState={currentQuestionState} mediatype={currentQuestion.mediatype} mediafile={mediafile} solutionMediatype={currentQuestion.solutionMediatype} solutionMediafile={solutionMediafile} />
                </div>
              </div>
            )
          case questiontype.SONG:
            return (
              <div>
                <SongQuestion currentQuestion={currentQuestion} />
              </div>
            )
          case questiontype.BUZZER:
            return (
              <div>
                <div style={{ height: "20VH" }}>
                  <TeamsBar teamsAndPlayers={teamAndPlayers.teams} currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                </div>
                <div style={{ height: "5VH" }}>
                  <QuestionInfoBar currentQuestion={currentQuestion} />
                </div>
                <div style={{ height: "74VH" }}>
                  <BuzzerQuestion currentQuestion={currentQuestion} currentQuestionState={currentQuestionState} mediatype={currentQuestion.mediatype} mediafile={mediafile} solutionMediatype={currentQuestion.solutionMediatype} solutionMediafile={solutionMediafile} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} />
                </div>
              </div>
            )
          case questiontype.FREETEXT:
            return (
              <div>
                <div style={{ height: "20VH" }}>
                  <TeamsBar teamsAndPlayers={teamAndPlayers.teams} currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                </div>
                <div style={{ height: "5VH" }}>
                  <QuestionInfoBar currentQuestion={currentQuestion} />
                </div>
                <div style={{ height: "74VH" }}>
                  <FreeTextQuestion currentQuestion={currentQuestion} currentQuestionState={currentQuestionState} mediatype={currentQuestion.mediatype} mediafile={mediafile} solutionMediatype={currentQuestion.solutionMediatype} solutionMediafile={solutionMediafile} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} />
                </div>
              </div>
            )

          case questiontype.FREENUMBER:
            return (
              <div>
                <div style={{ height: "20VH" }}>
                  <TeamsBar teamsAndPlayers={teamAndPlayers.teams} currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                </div>
                <div style={{ height: "5VH" }}>
                  <QuestionInfoBar currentQuestion={currentQuestion} />
                </div>
                <div style={{ height: "74VH" }}>
                  <FreeNumberQuestion currentQuestion={currentQuestion} currentQuestionState={currentQuestionState} mediatype={currentQuestion.mediatype} mediafile={mediafile} solutionMediatype={currentQuestion.solutionMediatype} solutionMediafile={solutionMediafile} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} />
                </div>
              </div>
            )

          case questiontype.MAP:
            return (
              <div>
                <div style={{ height: "20VH" }}>
                  <TeamsBar teamsAndPlayers={teamAndPlayers.teams} currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                </div>
                <div style={{ height: "5VH" }}>
                  <QuestionInfoBar currentQuestion={currentQuestion} />
                </div>
                <div style={{ height: "74VH" }}>
                  <MapQuestion currentQuestion={currentQuestion} currentQuestionState={currentQuestionState} mediatype={currentQuestion.mediatype} mediafile={mediafile} solutionMediatype={currentQuestion.solutionMediatype} solutionMediafile={solutionMediafile} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} />
                </div>
              </div>
            )

          case questiontype.GUESS:
            return (
              <div>
                <GuessQuestion currentQuestion={currentQuestion} />
              </div>
            )


            case questiontype.WHOKNOWSMORE:
            ////console.log("Switch Case" + JSON.stringify(answersFromTeams, null, 2));
            //console.log("Viewer Multiquestion")
            return (
              <div>
                <div style={{ height: "20VH" }}>
                  <TeamsBar teamsAndPlayers={teamAndPlayers.teams} currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                </div>
                <div style={{ height: "5VH" }}>
                  <QuestionInfoBar currentQuestion={currentQuestion} />
                </div>
                <div style={{ height: "74VH" }}>
                  <WhoKnowsMoreQuestion currentQuestion={currentQuestion} currentQuestionState={currentQuestionState} mediatype={currentQuestion.mediatype} mediafile={mediafile} solutionMediatype={currentQuestion.solutionMediatype} solutionMediafile={solutionMediafile} selectedAnswers={selectedAnswers}/>
                </div>
              </div>
            )


          default:
            break;
        }


      } else {
        return (
          <div>
            <h1>Viewer</h1>
            <Loader />
          </div>
        )
      }
      break
    case viewMode.STARTROUND:

      var rounds = []
      if (view2data !== undefined) {
        for (var r in view2data.gameConfig) {
          if (view2data.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            if (view2data.gameConfig[r].roundID === currentRound) {
              rounds.push(view2data.gameConfig[r])
            }
          }
        }
        return (


          <div className="infoBoard">
            <div className="infoBox">
            <div className="preQquestion">
                Round {currentRound}
              </div>

              <div className="catbubble">
                {Object.entries(rounds).map(([key, value]) =>
                  <div>
                    {value.category}<br />
                    Questions: {value.nQuestions}<br />
                  </div>
                )}
              </div>

            </div>
          </div>

        )
      }
      break;

    case viewMode.ENDROUND:
      return (

        <div className="infoBoard">
          <div className="infoBox">
            <div className='question'>
              End of Round {currentRound}
            </div>
          </div>
        </div>

      )

      break;

    case viewMode.SCOREBORD:
      return (
        <div>
          <Scoreboard teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} />
        </div>)

      break;


    default:
      return (
        <div>
          <h1>Viewer</h1>
        </div>
      )


  }




};

export default (Viewer);
