import React, { useState, useEffect } from 'react';


function QuestionInfoBar({currentQuestion,currentPoints,totalPoints}) {
      
         return(
            <div className='playerQuestionInfo'>
            <div>Round: {currentQuestion.questionID.R}</div>
            <div> Question: {currentQuestion.questionID.Q+1} </div>
            <div> Points: {currentPoints}</div>
            <div> Total Points: {totalPoints}</div>
            </div>
          )
   
}
export default (QuestionInfoBar);