import React, { useState, useEffect } from 'react';
import './style/index.scss';
import SimpleQuestion from "./P_SimpleQuestion.js";
import SongQuestion from "./V_SongQuestion.js";
//import MapQuestion from "./V_MapQuestion_old.js";
import GuessQuestion from "./V_GuessQuestion.js";
import BuzzerQuestion from "./P_BuzzerQuestion.js";
import FreeTextQuestion from './P_FreeTextQuestion.js';
import FreeNumberQuestion from './P_FreeNumberQuestion.js';
import MapQuestion from './P_MapQuestion.js';
import WhoKnowsMoreQuestion from './P_WhoKnowMoreQuestion.js';
import configData from "./config.json";



import Loader from "./Loader.js";
import QuestionInfoBar from "./P_QuestionInfoBar.js";
import Button from "./Button.js";
import { useCookies } from 'react-cookie';
import ImageMarker from "react-image-marker";
import { useWakeLock } from 'react-screen-wake-lock';



/*
import connection from './Enums.js';
import nameAvail from './Enums.js';
import questionState from './Enums.js';
import questiontype from './Enums.js';
import viewMode from './Enums.js';
*/

const questionState =
    {
        OPEN: 1,
        CLOSED: 2,
        SHOWANSWERS: 3,
        SHOWPOINTS: 4,
    }


   

//change in player,viewer,controler,points manager
const questiontype =
    {
        MULTICHOICE: 1,
        NUMBERGUESS: 2,
        BUZZER: 3,
        FREETEXT:4,
        FREENUMBER:5,
        MAP : 6,
        WHOKNOWSMORE:7,


    }


const mediatype=
{    
    TEXT: 1,
    AUDIO: 2,
    IMAGE: 3,
    VIDEO :4
}


    const viewMode =
    {
        DISCONNECTED: 0,
        LOBBY: 1,
        SUMMARY: 2,
        LOADQUESTION: 3,
        PREQUESTION: 4,
        QUESTION: 5,
        ANSWER: 6,
        STARTROUND: 7,
        ENDROUND: 8,
        SCOREBORD: 9,
        ALLTEAMS: 21,
        PLAYERTEAM: 22
    }


    const connection = {
        connected: 1,
        connectedAndJoined: 2,
        disconnected: 3,
    }

    const ledMode = {
        GREEN: 1,
        RED: 2,
        YELLOW: 3,
        BLUE: 4,
        GREENFLASH: 11,
        REDFLASH: 21,
        YELLOWFLASH: 31,
        BLUEFLASH: 41,
    }


    const nameAvail = {
        INITIAL: 1,
        TRUE: 2,
        FALSE: 3,

    }




function Player() {

  //const log = logger.createLogger();
  //const [message, setMessage] = useState("");
  //const [inputValue, setInputValue] = useState('');
  const [ws, setWs] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(connection.disconnected);
  //const [received, setreceived] = useState(false);
  const [allTeams,setAllTeams]=useState();
  const [teamID,setPlayerTeam]=useState();
  const [playerName,setPlayerName]=useState();
  const [teamName,setPlayerTeamName]=useState("");
  const [teamMember,setTeamMember]=useState({});
  const [nameAvailable,setNameAvailable]=useState(nameAvail.INITIAL); //0= undefined , true , false
  const [joinedTeam,setJoinedTeam]=useState(false);
  
  const [syncAnswer,setSyncAnswer]=useState({selectedAnswer:"-1",lockedIn:false});

  const [image,setImage]=useState();
  const [mp3,setMp3]=useState();

  const [cookieUser, setCookieUser] = useCookies(['user']);
  const [coords, setCoords] = useState({x: 0, y: 0});
  let [markers, setMarkers] = useState([]);



  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentRound, setCurrentRound] = useState();
  const [currentPoints, setCurrentPoints] = useState(0);
  const [currentQuestionState, setCurrentQuestionState] = useState(questionState.OPEN);
  const [totalPoints, setTotalPoints] = useState(0);
  const [viewerMode, setViewerMode] = useState(0);


  //const [gameJoinStatus, setgameJoinStatus] = useState('notJoined');
 /* 
  const [view2data, setView2data] = useState();
  const [teamAndPlayers, setTeamAndPlayers] = useState();
  const [answersFromTeams,setAnswerFromTeams]=useState();
  const [pointsForTeams,setPointsForTeams]=useState();
*/






const { isSupported, released, request, release } = useWakeLock({
  onRequest: () => alert('Screen Wake Lock: requested!'),
  onError: () => alert('An error happened 💥'),
  onRelease: () => alert('Screen Wake Lock: released!'),
});




  const handleConnect = () => {
    var url=""
    if (configData.socketssl){
      url= "wss://"+configData.serverURL+ ":"+ configData.serverPort
    }else{
      url= "ws://"+configData.serverURL+ ":"+ configData.serverPort
    }
    const newWs= new WebSocket(url);
    newWs.onopen = () => {
      console.log("onOpen")
      setConnectionStatus(connection.connected);
    };
    newWs.onclose = () => {
      setConnectionStatus(connection.disconnected);
    };


    newWs.onmessage = event => {
      
      const receivedMessage = JSON.parse(event.data);
      
      console.log("type:" + receivedMessage.type)
      console.log(JSON.stringify(receivedMessage, null, 2))
    

      switch (receivedMessage.type) {
        case "SpointsForQuestion":
          console.log("set " + receivedMessage.points + " for Question "+ receivedMessage.QuestionID.R + " " +receivedMessage.QuestionID.Q )
          setCurrentPoints(receivedMessage.points)
          setTotalPoints(receivedMessage.totalPoints)
          setCurrentQuestionState(questionState.SHOWPOINTS)

          break;
        case "SLockedIn":
          setSyncAnswer(syncAnswer => ({...syncAnswer, lockedIn: receivedMessage.lockedIn}))
          break;
        case "SsyncAnswer":
          setSyncAnswer(syncAnswer => ({...syncAnswer, selectedAnswer: receivedMessage.selectedAnswer,sugessor:receivedMessage.suggester}))
          break;
        case "img":
          setImage(receivedMessage.img)
          break;
        case "mp3":
          setMp3((receivedMessage.mp3))
 
          break;
        case "SAllTeams":
          setViewerMode(viewMode.ALLTEAMS)
          setAllTeams(receivedMessage.teams)
        break;
        case "SupdateTeamMember":
          setPlayerTeam(receivedMessage.teamID)
          setPlayerTeamName(receivedMessage.teamName)
          setTeamMember(receivedMessage.teamMember)
        break;
        case "SConfirmName":
          if (receivedMessage.available){
            setNameAvailable(nameAvail.TRUE)
          }else{
            setNameAvailable(nameAvail.FALSE)
          }
          setPlayerName(receivedMessage.name)
          setJoinedTeam(receivedMessage.joinedTeam)
          break;
        
        case "SGameConfig":
          //console.log(JSON.stringify(view2data, null, 2))
          //setView2data(receivedMessage);
          break
        case "mode":
          setViewerMode(receivedMessage.mode);
          console.log("received Mode");
          break
        case "updatePlayers":
          //setTeamAndPlayers(receivedMessage);
          break

        case "SloadQuestion":
        setCurrentQuestion(receivedMessage.question)
        setCurrentQuestionState(questionState.OPEN)
        setViewerMode(viewMode.PREQUESTION)
        setSyncAnswer(syncAnswer => ({...syncAnswer, selectedAnswer:-1}))
          break
        
        case "SstartQuestion":
       
        setViewerMode(viewMode.QUESTION)
          break

        case "ScloseQuestion":
          setCurrentQuestionState(questionState.CLOSED)
          break

        case "SshowAnswer":
          setCurrentQuestionState(questionState.SHOWANSWERS)
          break
        case "SteamAnswer":
          /*
          setAnswerFromTeams(prevState => {
            return { ...prevState, [receivedMessage.teamID]: receivedMessage.answer };
          });*/
          break

        case "SallTeamAnswers":
        /*    
        setPointsForTeams(prevState => {
              return { ...prevState, [receivedMessage.teamID]: {["answers"]:receivedMessage.answers,["totalPoints"]:receivedMessage.totalPoints}} 
            });*/
            break
        

        case "SstartGame":
          setViewerMode(viewMode.SUMMARY)
          break
        case "SstartRound":
          setCurrentRound(receivedMessage.round)
          //setCurrentQuestionState(questionState.STARTROUND)
          setViewerMode(viewMode.STARTROUND)
          break
        
        case "SendRound":
          setViewerMode(viewMode.ENDROUND)  
          //setCurrentQuestionState(questionState.ENDROUND)
          break
          

        default:
          break

      }
      //setreceived(true)



    };
    setWs(newWs);

  };

  useEffect(() => {
    if (ws) {
      return () => {
        ws.close();
      };
    }

  }, [ws]);

  useEffect(() => {
    if (connectionStatus === connection.connected&& ws!==null) {
      console.log("handleSendMessage")
      const connect2GameMsg = { type: "PrequestTeams"}
      ws.send(JSON.stringify(connect2GameMsg));
    }

  }, [connectionStatus]);


  useEffect(() => {
    if (joinedTeam){
      setViewerMode(viewMode.PLAYERTEAM);
      setCookieUser("name",playerName,{});
      setCookieUser("team",teamID,{});
      
    }
  }, [joinedTeam]);

  /*
  useEffect(() => {
    console.log("useffect" + JSON.stringify(answersFromTeams, null, 2))
  }, [answersFromTeams]);*/

  useEffect(() => {
    console.log("markers" + JSON.stringify(markers, null, 2))
  }, [markers]);



  const handleImgMouseMove = event => {
    setCoords({
      x: event.clientX-event.target.offsetLeft,
      y: event.clientY-event.target.offsetTop,
    });
  };
  const handleImgMouseDown = event => {
    console.log({coords})
  };
  

  const starPlayer = () => {
    const audioElement = new Audio("test.mp3");
    audioElement.play()
  };

  
/*
  useEffect(() => {
    const handleWindowMouseMove = event => {
      setCoords({
        x: event.clientX,
        y: event.clientY,
      });
    };
    //window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleWindowMouseMove,
      );
    };
  }, []);
  */


  const handleJoinGame = (name) => {
    if (ws.readyState === WebSocket.OPEN) {
      const joinGameMsg = { type: "PjoinGame" ,"playerName":name}
      setConnectionStatus(connection.connectedAndJoined)
      ws.send(JSON.stringify(joinGameMsg));
      console.log("joinGame")
    }
  }

  const handleJoinTeam = (teamID) => {
    if (ws.readyState === WebSocket.OPEN) {
      //handleJoinGame(playerName);
      const joinGameMsg = { type: "PjoinGame" ,"teamID":parseInt(teamID),"playerName":playerName}
      ws.send(JSON.stringify(joinGameMsg));
      setPlayerName(playerName)
      //setViewerMode(viewMode.PLAYERTEAM)
      console.log("joinTeam")
      
    }
  }

  const leaveTeam = () => {
    if (ws.readyState === WebSocket.OPEN) {
    
      const leaveTeamMsg = {"type": "PleaveTeam", "teamID": parseInt(teamID)}
      ws.send(JSON.stringify(leaveTeamMsg));
      setPlayerTeam(undefined)
      setPlayerTeamName("")
      setViewerMode(viewMode.ALLTEAMS)
      setJoinedTeam(false)
      console.log("leaveTeam")
    }
  }

  const flashPlayer = () => {
  if (ws.readyState === WebSocket.OPEN) {
    const flashPlayerMsg = {"type": "PflashPlayer"}
    ws.send(JSON.stringify(flashPlayerMsg));
    console.log("Flash")
  }
}



  function inputName(event) {
    if (ws.readyState === WebSocket.OPEN) {
      //check if name is available and valid
      console.log(event.target.value);
      const filter = event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9]/gi, '').substring(0, 14)
      const checkNameMsg = { "type": "PcheckName" ,"playerName":filter}
      ws.send(JSON.stringify(checkNameMsg));
    }
  }

  function play(audio) {
    audio.play()
  }
  
  function stop(audio) {
    audio.pause()
  }

  function replay(audio) {
    audio.pause();
    audio.currentTime = 0;
    audio.play()

  }
  function isScreenLockSupported() {
    return ('wakeLock' in navigator);
   }

   async function getScreenLock() {
    if(isScreenLockSupported()){
      let screenLock;
      try {
         screenLock = await navigator.wakeLock.request('screen');
      } catch(err) {
         console.log(err.name, err.message);
      }
      
      return screenLock;

    }
  }

  switch (viewerMode) {
    case viewMode.ALLTEAMS:
    
    console.log("Teams" + JSON.stringify(allTeams, null, 2));
   
     
  console.log(getScreenLock())  
    return(
    
      <div className='centerLobby'>
      <main className="mainConnect">
        <div id="header">
         
          <h1 className='room'>Join Team</h1>
        </div>
        <div id="lobbyboard">
       
        <br/>
       
        <input onChange={inputName} name="name" value={playerName} placeholder="Enter Name"/>
        
        {nameAvailable===nameAvail.INITIAL?<div className='playerNameAvailable'>pick Name</div>:nameAvailable===nameAvail.TRUE?<div className='playerNameAvailable'>Name available</div>:<div className='playerNameNotAvailable'>Name not available</div>}

        {Object.entries(allTeams).map(([key,team]) =>
              <div key={key}>
                <Button onClick={() => handleJoinTeam(key)} disabled={nameAvailable===nameAvail.TRUE?false:true}>
                  Join Team {team[key]} 
                </Button>
              </div>
        )}
        </div>
      </main>
      <div className='connectedStatus'>
        <br/>{connectionStatus === connection.connected ? 'Connected to server' : 'Not connected to server'}
      </div>
    </div>
      
      
      )

      break;
      case viewMode.PLAYERTEAM:
      case viewMode.LOBBY:
      //const audioElement = new Audio(`data:audio/mp3;base64,${mp3}`);
      /*
        <div onMouseMove={handleImgMouseMove} onMouseDown={handleImgMouseDown}>
            <img src={`data:image/jpeg;base64,${image}`} />
            ({coords.x}, {coords.y})
          </div>

  <ImageMarker
            src={`data:image/jpeg;base64,${image}`}
            markers={markers}
            onAddMarker={(marker) => setMarkers((prev) => [...prev, marker])}
            markerComponent={CustomMarker}
          />
          <audio controls src={`data:audio/mp3;base64,${mp3}`} />
          <Button onClick={() => play(audioElement)}>
            play sound
          </Button>

          <Button onClick={() => stop(audioElement)}>
            stop sound
          </Button>


          <Button onClick={() => replay(audioElement)}>
            replay sound
          </Button>


      */
      
      return(
             
        <div className='centerPlayer'>
        <main className="mainConnect">
          <div id="header">
            <h1 className='room'>Your Team</h1>
          </div>
          <div className="playerTeamBubble">
          <div className="playerTeamName"> {teamName}</div>
          {Object.entries(teamMember).map(([key,value]) =>
            <div key={key}>
              {value.name} 
            </div>
           )}
            </div> 

            <div className='blink_me'>Waiting for Gamemaster to start the game</div>
            <button className="pingButton" onClick={() => flashPlayer()}>
                      Flash me!
             </button>
             <br/>

        </main>
        
        
        
        <Button onClick={() => leaveTeam()}>
                      Leave Team
        </Button>
        <div className='connectedStatus'>
          <br/>{connectionStatus === connection.connected ? 'Connected to server' : 'Not connected to server'}
        </div>
      </div>
            
          )

      case viewMode.DISCONNECTED: //not connected 
      var date = new Date();
      var timestamp = date.getTime();
      
      
              /*
 CookieUser Name: {cookieUser.name} <br/>
            CookieUser Team: {cookieUser.team} <br/>

              */


      return (
        
        <div className='centerLobby'>
          <main className="mainConnect">
            <div id="header">
            {/* <img src={process.env.PUBLIC_URL + "./alstomlogo.png"}/>*/}
              <h1 className='room'>Willkommen </h1>
              <div className='blink_me'>zum Geburtstagsquiz </div>
            </div>
            <div id="lobbyboard">
              <Button onClick={()=>handleConnect()} disabled={connectionStatus === connection.connected}>
              {connectionStatus === connection.connected ? 'Connected!' : 'Press to connect'}
              </Button>
              <img src={configData.startImgPlayer} width={"100%"}/>
            </div>
            
          </main>
          <div className='connectedStatus'>
            <br/>{connectionStatus === connection.connected ? 'Connected to server' : 'Not connected to server'}
          </div>
        </div>
        
      );
    break;
    //case viewMode.LOBBY: //show all teams, lobby
    /*  
    if (teamAndPlayers !== undefined) {
        return (
          <div>
            <h1>Viewer</h1>
            <div className="grid-container_teams">
              {Object.entries(teamAndPlayers.teams).map(([key, value]) =>
                <div className="grid-item_teams">
                  {value.teamName} <br />
                  {Object.entries(value.teammember).map((player) =>
                    <li>{player[1]}</li>)
                  }
                </div>

              )}
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <h1>Viewer</h1>
            No Player data received
          </div>
        )
      }
    */
     // break;

    case viewMode.SUMMARY: //show categories
      /*
      var rounds = []
      if (view2data !== undefined) {
        for (var r in view2data.gameConfig) {
          if (view2data.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            rounds.push(view2data.gameConfig[r])
          }
        }
        return (
          <div className="grid-container_categories">
            {Object.entries(rounds).map(([key, value]) =>
              <div className="grid-item_categories">
                Round:{value.roundID} <br />
                Category:{value.category}<br />
                Questions:{value.nQuestions}<br />

              </div>

            )}
          </div>
        )
      }
      else {*/
        return (
          <div className="infoBoard">
          <div className="infoBox">
            <div className='room'>
            Look at the big screen for categories
            </div>
             
        </div>
        </div>
   
        )
      //}
      break

    case viewMode.LOADQUESTION:
    console.log("Loading ...")  
    return (
        <div>
          <h1>Loading Question</h1>
         
        </div>
      )
      break;
    case viewMode.PREQUESTION: // Prequestion (load Question)
      /*neu*/

      if (currentQuestion !== undefined) {
        return (
        
          
          <div className="infoBoard">
          <div className="infoBox">
          <div className='playerPreQround'>Round {currentQuestion.questionID.R}</div>
            <div className='playerPreQround'>
            Question {currentQuestion.questionID.Q+1}
            </div>
            
            <div className="catbubble">
            Points: {currentQuestion.points}<br/>
            Category:<br/> {currentQuestion.category}
              </div>
          </div>
        </div>
        )
      } else {
        return (
         
          <div className="infoBoard">
          <div className="infoBox">
            <div className='room'>
             Loading Question
            </div>
             <Loader />
          </div>
        </div>
       
        )
      }


      break;
    
      case viewMode.ANSWER:
      case viewMode.QUESTION: //show question
      if (currentQuestion !== undefined ) {
        switch (currentQuestion.questiontype){
          case questiontype.MULTICHOICE:
          return(
            <div>
                <QuestionInfoBar currentQuestion={currentQuestion} currentPoints={currentPoints} totalPoints={totalPoints}/>
                <SimpleQuestion currentQuestion={currentQuestion} syncAnswer={syncAnswer} currentQuestionState={currentQuestionState} ws={ws} currentPoints={currentPoints}/>
            </div>
            )
            case questiontype.FREETEXT:
            return(
            <div>
              <QuestionInfoBar currentQuestion={currentQuestion} currentPoints={currentPoints} totalPoints={totalPoints}/>
              <FreeTextQuestion currentQuestion={currentQuestion} syncAnswer={syncAnswer} currentQuestionState={currentQuestionState} ws={ws} currentPoints={currentPoints} nTeamMembers={teamMember.length}/>
            </div>
            )
            case questiontype.FREENUMBER:
              return(
              <div>
                <QuestionInfoBar currentQuestion={currentQuestion} currentPoints={currentPoints} totalPoints={totalPoints}/>
                <FreeNumberQuestion currentQuestion={currentQuestion} syncAnswer={syncAnswer} currentQuestionState={currentQuestionState} ws={ws} currentPoints={currentPoints} nTeamMembers={teamMember.length}/>
              </div>
              )
            
            
            case questiontype.BUZZER:
            return(
            <div>
              <QuestionInfoBar currentQuestion={currentQuestion} currentPoints={currentPoints} totalPoints={totalPoints}/>
              <BuzzerQuestion currentQuestion={currentQuestion} syncAnswer={syncAnswer} currentQuestionState={currentQuestionState} ws={ws} currentPoints={currentPoints}/>
            </div>
            )
            case questiontype.MAP:
            return(
              <div>
              <QuestionInfoBar currentQuestion={currentQuestion} currentPoints={currentPoints} totalPoints={totalPoints}/>
              <MapQuestion currentQuestion={currentQuestion} syncAnswer={syncAnswer} currentQuestionState={currentQuestionState} ws={ws} currentPoints={currentPoints} nTeamMembers={teamMember.length} mapFile={image}/>
            </div>
            )

            case questiontype.GUESS:
            return(
            <div>
              <GuessQuestion currentQuestion={currentQuestion} />
            </div>
            )

            case questiontype.WHOKNOWSMORE:
              return(
              <div>
                <QuestionInfoBar currentQuestion={currentQuestion} currentPoints={currentPoints} totalPoints={totalPoints}/>
                <WhoKnowsMoreQuestion currentQuestion={currentQuestion}/>
              </div>
              )

            
          default:
            break;
        }


      } else {
        return (
          <div>
            <h1>Player</h1>
            <Loader/>
            </div>
        )
      }
      break
    case viewMode.STARTROUND:
     /*
      var rounds = []
      if (view2data !== undefined) {
        for (var r in view2data.gameConfig) {
          if (view2data.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            if (view2data.gameConfig[r].roundID === currentRound) {
              rounds.push(view2data.gameConfig[r])
            }
          }
        }
        return (
          <div >
            <h1>Start of Round {currentRound}</h1>
            {Object.entries(rounds).map(([key, value]) =>
              <div>
                Round:{value.roundID} <br />
                Category:{value.category}<br />
              </div>
            )}
          </div>
        )
      }
      else {*/
        return (
          <div className="infoBoard">
                <div className="infoBox">
                  <div className='playerPreQround'>
                    Round {currentRound}
                  </div>
                 
                </div>
              </div>
         
        
        
       
        )
      //}

      break;
    
    case viewMode.ENDROUND:
      return (
     
       <div className="infoBoard">
       <div className="infoBox">
         <div className='playerPreQround'>
         End of Round {currentRound}
         </div>
        
       </div>
     </div>
      
      
      )

      break;
    
    case viewMode.SCOREBORD:
      //{teamName}  
    return (
       
        <div className="infoBoard">
        <div className="infoBox">
          <div className='playerPreQround'>
          Total Points:
          </div>
          <div className='playerPreQround'>
          {totalPoints}
          </div>
          
        </div>
      </div>
        
        
        
        )
        
     break;


    default:
      return (
         <div className='centerLobby'>
          <main className="mainConnect">
            <div id="header">
              <h1 className='room'>Welcome</h1>
            </div>
            <div id="lobbyboard">
              <Button onClick={()=>handleConnect()} disabled={connectionStatus === connection.connected}>
              {connectionStatus === connection.connected ? 'Connected!' : 'Press to connect'}
              </Button>
            </div>
            
          </main>
          <div className='connectedStatus'>
            <br/>{connectionStatus === connection.connected ? 'Connected to server' : 'Not connected to server'}
          </div>
        </div>
      )
    

  }
};
export default (Player);
