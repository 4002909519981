import React from 'react';
import Led from "./Led.js"




const AnswerCorrect=
{
    NOTRATED :1,
    INCORRECT :2,
    CORRECT :3,
}


const teamQuestionState =
{
  NOTLOCKEDIN: 1,
  LOCKEDIN: 2,
  CORRECT: 3,
  INCORRECT: 4,
}


const questionState= 
{
    OPEN :1,
    CLOSED :2,
    SHOWANSWERS : 3,
    SHOWPOINTS : 4,    
}


const TeamsBar = ({ teamsAndPlayers, currentQuestion, teamAnswers, currentQuestionState }) => {


  const lockedIn = {}
  const totalPoints = {}
  

  for (let index = 0; index < teamsAndPlayers.length; index++) {
    lockedIn[teamsAndPlayers[index].teamID] = [teamQuestionState.NOTLOCKEDIN, "0.0", "teamsBarBubbles"]
    totalPoints[teamsAndPlayers[index].teamID] = 0
  }

  //lockedIn[key]=[true,answer.buzzerTime];

  //console.log("teamanswers" + teamAnswers);
  for (const key in teamAnswers) {

    if (Object.hasOwnProperty.call(teamAnswers, key)) {
      //all answers from Team "key"
      const answers = teamAnswers[key];
      const questionLockedIn=answers["lockedIn"]
      
      totalPoints[key]=answers["totalPoints"]
      for (const answerkey in answers["answers"]) {
        const answer=answers["answers"][answerkey]
        //console.log(teamAnswers)
        if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && questionLockedIn && answer.answeredCorrectly===AnswerCorrect.NOTRATED &&answers["lockedIn"]) {
          //anwer matches current Question
          lockedIn[key][0] = teamQuestionState.LOCKEDIN;
          lockedIn[key][1] = answer.buzzerTime.toFixed(3);
          lockedIn[key][2] = "teamsBarBubblesLockedin teamsBarBubbles teamBarFontSize";
          console.log("1:lockedIn")
        }
        
          if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.answeredCorrectly===AnswerCorrect.CORRECT && currentQuestionState>questionState.CLOSED ) {
            //anwer matches current Question
            lockedIn[key][0] = teamQuestionState.CORRECT;
            lockedIn[key][1] = answer.buzzerTime.toFixed(3);
            lockedIn[key][2] = "teamsBarBubblesCorrect teamsBarBubbles teamBarFontSize";
            console.log("2:correctAnswer")
          }
          if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && answer.answeredCorrectly === AnswerCorrect.INCORRECT && currentQuestionState>questionState.CLOSED ) {
            //anwer matches current Question
            lockedIn[key][0] = teamQuestionState.INCORRECT;
            lockedIn[key][1] = answer.buzzerTime.toFixed(3);
            lockedIn[key][2] = "teamsBarBubblesWrong teamsBarBubbles teamBarFontSize";
            console.log("3:wrongAnswer")
          }
          if (answer.questionID.R === currentQuestion.questionID.R && answer.questionID.Q === currentQuestion.questionID.Q && questionLockedIn && answer.answeredCorrectly!==AnswerCorrect.NOTRATED &&currentQuestionState===questionState.OPEN ) {
            //anwer matches current Question
            lockedIn[key][0] = teamQuestionState.INCORRECT;
            lockedIn[key][1] = answer.buzzerTime.toFixed(3);
            lockedIn[key][2] = "teamsBarBubblesLockedin teamsBarBubbles teamBarFontSize";
            console.log("4:team blocked")
          }


      }
    }

  }

 
  return (
    <div>
      <div className="teamsBar">
        {Object.entries(teamsAndPlayers).map(([key, value]) =>

          <div className={lockedIn[key][2]}>

            <div className="teamNameBar">{value.teamName} </div>
            {/*lockedIn[key][0] !== teamQuestionState.NOTLOCKEDIN && lockedIn[key][1]>0? "Time:" + lockedIn[key][1] + "s" : currentQuestionState===questionState.OPEN?<Led mode={3}/>: "" */}
            {lockedIn[key][0] !== teamQuestionState.NOTLOCKEDIN && lockedIn[key][1]>0?   //Team lockedin
            lockedIn[key][0] ===teamQuestionState.INCORRECT &&currentQuestionState<questionState.SHOWANSWERS?
            <div className="viewerTeamTime teamBarFontSize">Blocked </div> //show time
            :
            <div className="viewerTeamTime teamBarFontSize">Time:  {lockedIn[key][1]} s </div> //show time
            
            
            :   
            currentQuestionState===questionState.OPEN?  //not lockedin 
            <div className="viewerblinkTime teamBarFontSize">No Answer</div> : <br/>} 

            {/*lockedIn[key][0] !== teamQuestionState.NOTLOCKEDIN && <br />*/}
            <div className="viewerTeamTotelPoints teamBarFontSize" >Points: {totalPoints[key]}</div>
          </div>



        )}
      </div>


    </div>
  );
};

export default TeamsBar;