import React, { useState, useEffect, useRef } from 'react';

import { Player, ControlBar } from 'video-react';

import ReactPlayer from 'react-player'
import configData from "./config.json";

import "../node_modules/video-react/dist/video-react.css";

const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}

const mediatypeE =
{
  TEXT: 1,
  AUDIO: 2,
  IMAGE: 3,
  VIDEO: 4
}
function play(audio) {
  audio.play()
}
function stop(audio) {
  audio.pause()
}


var ended=false

function VideoPlayer({ mediafile, play }) {

  const [wrapperclass, setWrapperclass] = useState("wrapper_fullscreen")

  const videoRef = useRef(null);
  
  const stopVideo = () => {
    videoRef.current.pause();
  };

  const playVideo = () => {
    videoRef.current.play();
  };


  const handleVideoEnded = () => {
    stopVideo();
    ended=true
    setWrapperclass("wrapper")
  };



  
  var src = configData.mediaServerURL + ":" + configData.mediaServerPort + "/" + mediafile
  if (configData.socketssl) {
    src = "https://" + src
  } else {
    src = "http://" + src
  }

  if (!play) {
    stopVideo()
    if (wrapperclass!=="wrapper"){
      setWrapperclass("wrapper")
    }
  }

  if (play) {
    if (videoRef.current !== null && !ended) {
      playVideo()
      if (wrapperclass!=="wrapper_fullscreen"){
        setWrapperclass("wrapper_fullscreen")
      }
    }
  }

  return (
    
    
    <div className={wrapperclass}>
      <video src={src} preload="auto" autoPlay={true} width="100%" height="100%" onEnded={handleVideoEnded} ref={videoRef}>
      </video>
    </div>

  )

}

//const MemoizedAudioPlayer = React.memo(AudioPlayer);
export default (VideoPlayer);