import configData from "./config.json";
import React, { useState, useEffect } from 'react';
import Button from "./Button.js";
import Loader from "./Loader.js";
import SimpleQuestion from "./C_SimpleQuestion.js";
import BuzzerQuestion from './C_BuzzerQuestion.js';
import FreeNumberQuestion from './C_FreeNumberQuestion.js';
import FreeTextQuestion from './C_FreeTextQuestion.js';

import GameStart from "./C_RoundQuestionControls.js";
import { RoundInput } from "./C_RoundQuestionControls.js";
import { QuestionInput } from "./C_RoundQuestionControls.js";
import { RoundStart } from "./C_RoundQuestionControls.js";
import { QuestionLoad } from "./C_RoundQuestionControls.js";
import { RoundEnd } from "./C_RoundQuestionControls.js";
import PointsManager from "./C_PointsManager.js";
import MapQuestion from './C_MapQuestion.js';
import WhoKnowsMoreQuestion from './C_WhoKnowsMoreQuestion.js';



const questionState =
{
  OPEN: 1,
  CLOSED: 2,
  SHOWANSWERS: 3,
  SHOWPOINTS: 4,
}

//change in player,viewer,controler
const questiontype =
{
  MULTICHOICE: 1,
  NUMBERGUESS: 2,
  BUZZER: 3,
  FREETEXT: 4,
  FREENUMBER: 5,
  MAP: 6,
  WHOKNOWSMORE: 7,


}


const mediatype =
{
  TEXT: 1,
  AUDIO: 2,
  IMAGE: 3,
  VIDEO: 4
}






const viewMode =
{
  DISCONNECTED: 0,
  LOBBY: 1,
  SUMMARY: 2,
  LOADQUESTION: 3,
  PREQUESTION: 4,
  QUESTION: 5,
  ANSWER: 6,
  STARTROUND: 7,
  ENDROUND: 8,
  SCOREBORD: 9
}


const connection = {
  connected: 1,
  connectedAndJoined: 2,
  disconnected: 3,
}



//const Viewer = ({ location: { pathname } }) => {
//   const connected = useSelector(state => state.websocket.connected);
function Controler() {
  const [messages, setMessages] = useState("");
  const [inputValue, setInputValue] = useState('');
  const [ws, setWs] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');


  const [currentQuestion, setCurrentQuestion] = useState()
  const [currentRound, setCurrentRound] = useState(0)
  const [selectedQuestionID, setSelectedQuestionID] = useState({ R: 0, Q: 0 })

  const [currentQuestionState, setCurrentQuestionState] = useState(questionState.OPEN)

  const [gameData, setGameData] = useState();
  const [viewerMode, setViewerMode] = useState(0);
  const [teamAndPlayers, setTeamAndPlayers] = useState();
  const [answersFromTeams, setAnswerFromTeams] = useState()

  const [selectedAnswers, setSelectedAnswers] = useState([])

  const [pointsForTeams, setPointsForTeams] = useState()




  const handleConnect = () => {


    var url = ""
    if (configData.socketssl) {
      url = "wss://" + configData.serverURL + ":" + configData.serverPort
    } else {
      url = "ws://" + configData.serverURL + ":" + configData.serverPort
    }
    const newWs = new WebSocket(url);
    newWs.onopen = () => {
      //console.log("onOpen")
      setConnectionStatus(connection.connected)
    };

    newWs.onclose = () => {
      setConnectionStatus(connection.disconnected);
    };

    newWs.onmessage = event => {

      const receivedMessage = JSON.parse(event.data);

      //console.log("type:" + receivedMessage.type)
      //console.log(JSON.stringify(receivedMessage, null, 2))


      switch (receivedMessage.type) {
        case "SGameConfig":
          ////console.log(JSON.stringify(gameData, null, 2))
          setGameData(receivedMessage);
          break
        case "mode":
          setViewerMode(receivedMessage.mode);
          //console.log("received Mode");
          break
        case "updatePlayers":
          setTeamAndPlayers(receivedMessage);
          break
        case "SstartGame":
          setViewerMode(viewMode.SUMMARY)
          break

        case "SstartRound":
          setCurrentRound(receivedMessage.round)
          setCurrentQuestionState(questionState.STARTROUND)
          setViewerMode(viewMode.STARTROUND)
          break

        case "SloadQuestion":
          setCurrentQuestion(receivedMessage.question)
          setCurrentQuestionState(questionState.OPEN)
          break

        case "SallTeamAnswers":
          setAnswerFromTeams(prevState => {
            return { ...prevState, [receivedMessage.teamID]: { ["answers"]: receivedMessage.answers, ["totalPoints"]: receivedMessage.totalPoints, ["lockedIn"]: receivedMessage.lockedIn } }
          });
          break
        case "SselectedAnswers":
          console.log("selected Answers")
          setSelectedAnswers(receivedMessage.selectedAnswers)
          break


        /*
        case "SteamAnswer":
          setAnswerFromTeams(prevState => {
            return { ...prevState, [receivedMessage.teamID]: receivedMessage.answer };
          });
          break*/
        default:
          break

        //const messageData = JSON.parse(receivedMessage);
        ////console.log()
        //setMessages(prevMessages => [...prevMessages, messageData]);
      }


    };

    setWs(newWs);
  };

  useEffect(() => {
    if (ws) {
      return () => {
        ws.close();
      };
    }
  }, [ws]);


  useEffect(() => {
    if (connectionStatus === connection.connected) {
      ////console.log("handleSendMessage")
      handleJoinGame();
    }

  }, [connectionStatus]);


  useEffect(() => {
    ////console.log("useffect" + JSON.stringify(answersFromTeams, null, 2))
  }, [answersFromTeams]);



  /*const handleInputChange = event => {
    setInputValue(event.target.value);
  };*/


  function getKeyFromValueEnum(enumconst, value) {
    for (var key in enumconst) {
      var valueEnum = enumconst[key];
      if (valueEnum === value) {
        return key
      }
    }
    return "unknown"
  }





  const setNextQuestionId = () => {
    var newId = { R: 0, Q: 0 }
    const nRounds = gameData.gameConfig.nRounds
    const nQuestion = gameData.gameConfig[selectedQuestionID.R].nQuestions
    if (selectedQuestionID.Q + 1 < nQuestion) {
      newId.Q = selectedQuestionID.Q + 1
      newId.R = selectedQuestionID.R
    } else {
      if (nRounds - 1 !== selectedQuestionID.R) {
        newId.Q = 0
        newId.R = selectedQuestionID.R + 1
      }
      else {
        newId = selectedQuestionID
      }
    }
    setSelectedQuestionID(newId)
  }

  const handleJoinGame = () => {
    if (ws.readyState === WebSocket.OPEN) {
      const joinGameMsg = { type: "CjoinGame" }
      setConnectionStatus(connection.connectedAndJoined)
      ws.send(JSON.stringify(joinGameMsg));
      ////console.log("joinGame")
    }
  }

  const startGame = () => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { type: "CstartGame" }

      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }



  const startQuestion = (round, question) => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { "type": "CstartQuestion", "questionID": { "R": round, "Q": question } }
      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }

  const closeQuestion = (round, question) => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { "type": "CcloseQuestion", "questionID": { "R": round, "Q": question } }
      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }

  const showAnswer = (round, question) => {
    if (ws.readyState === WebSocket.OPEN) {
      const msg = { "type": "CshowCorrectAnswer", "questionID": { "R": round, "Q": question } }
      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }

  const showScoreboard = () => {
    if (ws.readyState === WebSocket.OPEN) {
      //const msg = { "type": "CviewerMode", "questionID": { "R": round, "Q": question } }
      const msg = { "type": "CviewerMode", "mode": viewMode.SCOREBORD }
      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }

  const showLobby = () => {
    if (ws.readyState === WebSocket.OPEN) {
      //const msg = { "type": "CviewerMode", "questionID": { "R": round, "Q": question } }
      const msg = { "type": "CviewerMode", "mode": viewMode.LOBBY }
      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }




  const showSummary = () => {
    if (ws.readyState === WebSocket.OPEN) {
      //const msg = { "type": "CviewerMode", "questionID": { "R": round, "Q": question } }
      const msg = { "type": "CviewerMode", "mode": viewMode.SUMMARY }
      ws.send(JSON.stringify(msg));
      ////console.log("msg")
    }
  }




  const handleChangeOnRound = event => {

    const value = parseInt(event.target.value);
    setSelectedQuestionID({ ...selectedQuestionID, R: value })

    //setCurrentRound(result);
  };
  const handleChangeOnQuestion = event => {
    const value = parseInt(event.target.value);
    //(syncAnswer => ({...syncAnswer, selectedAnswer:-1}))
    //setCurrentQuestion(currentQuestion=>({...currentQuestion,questionID:{Q:value}}))
    setSelectedQuestionID({ ...selectedQuestionID, Q: value })

    //setCurrentQuestion(currentQuestion.questionID.Q);
  };



  // //console.log(connectionStatus)
  //if (connectionStatus === connection.connected) {
  //  //console.log("handleSendMessage")
  //handleSendMessage();
  //}


  switch (viewerMode) {
    case viewMode.DISCONNECTED: //not connected 
      return (
        <div className='centerLobby'>
          <main class="mainConnect">
            <div id="header">
              <h1 className='room'>Welcome Controler</h1>
            </div>
            <div id="lobbyboard">
              <Button onClick={() => handleConnect()} disabled={connectionStatus === connection.connected}>
                {connectionStatus === connection.disconnected ? 'Connected!' : 'Press to connect'}
              </Button>
            </div>

          </main>
          <div className='connectedStatus'>
            <br />{connectionStatus === connection.disconnected ? 'Connected to server' : 'Not connected to server'}
          </div>
        </div>
      );
    //break;
    case viewMode.LOBBY: //show all teams, lobby
      if (teamAndPlayers !== undefined) {
        //console.log(connectionStatus);
        return (
          <div className='centerLobby'>
            <main class="mainLobby">
              <div id="header">
                <h1 className='room'>Controler Lobby</h1>
              </div>

              <div id="lobbyboard">
                <div className="lobbyBoard">
                  {Object.entries(teamAndPlayers.teams).map(([key, value]) =>
                    <div className="lobbyTeamBubble">
                      <div class="teamNameLobby">{value.teamName} </div>
                      {Object.entries(value.teammember).map((player) =>
                        <li>{player[1]}</li>
                      )
                      }
                    </div>

                  )}
                </div>
                <GameStart ws={ws} />
              </div>

            </main>
            <div className='connectedStatus'>
              {connectionStatus !== connection.disconnected ? 'Connected to server' : 'Not connected to server'}
            </div>

          </div>
        )
      } else {
        return (
          <div>
            <h1>Controler</h1>
            No Player data received
          </div>
        )
      }
    //break;

    case viewMode.SUMMARY: //show categories
      var rounds = []
      if (gameData !== undefined) {
        for (var r in gameData.gameConfig) {
          if (gameData.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            rounds.push(gameData.gameConfig[r])
          }
        }
        return (
          <div className="grid-container_categories">
            {Object.entries(rounds).map(([key, value]) =>
              <div className="grid-item_categories">
                Round:{value.roundID} <br />
                Category:{value.category}<br />
                Questions:{value.nQuestions}<br />
              </div>
            )}

            <div className='controlButtonsGrid'>

              <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
              <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
              <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
              <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
              <Button onClick={() => showLobby()}>Show Lobby</Button>
            </div>

          </div>
        )

      }

    case viewMode.LOADQUESTION:

      //console.log("Loading ...")
      return (
        <div className="infoBoard">
          <div className="infoBox">
            <div className='round'>Round: {selectedQuestionID.R}</div>
            <div className='question'>
              Loading Question {selectedQuestionID.Q}
            </div>
          </div>
        </div>
      )

      break;
    case viewMode.PREQUESTION: // Prequestion (load Question)
      if (currentQuestion !== undefined) {
        const questiontypeString = getKeyFromValueEnum(questiontype, currentQuestion.questiontype)
        const mediatypeString = getKeyFromValueEnum(mediatype, currentQuestion.mediatype)



        return (


          <div className="infoBoard">
            <div className="infoBox">
              <div className='round'>Round: {currentQuestion.questionID.R}</div>
              <div className='question'>
                Loaded Question {currentQuestion.questionID.Q}
              </div>

              <div className="catbubble">
                Points: {currentQuestion.points}<br />
                Category: {currentQuestion.category}<br />
                Question: {currentQuestion.question}<br />
                Type: {questiontypeString} <br />
                MediaType: {mediatypeString}
              </div>
            </div>
            <div className='controlButtonsGrid'>

              <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
              <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
              <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
              <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <Button onClick={() => showSummary()}>Show Summary</Button>
              <Button onClick={() => startQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Start Question</Button>
              <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
            </div>

          </div>
        )
      } else {
        return (
          <div className="infoBoard">
            <div className="infoBox">
              <div className='round'></div>
              <div className='question'>
                Loading Question
              </div>

            </div>
            <div className='controlButtonsGrid'>

              <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
              <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
              <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
              <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <Button onClick={() => showSummary()}>Show Summary</Button>
              <Button onClick={() => startQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Start Question</Button>
              <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
            </div>
          </div>
        )
      }
      break;

    case viewMode.ANSWER:
    case viewMode.QUESTION: //show question
      if (currentQuestion !== undefined) {
        switch (currentQuestion.questiontype) {
          case questiontype.MULTICHOICE:
            ////console.log("Switch Case" + JSON.stringify(answersFromTeams, null, 2));
            return (
              <div>
                <SimpleQuestion currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                <div className='controlButtonsGrid'>

                  <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
                  <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
                  <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
                  <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <Button onClick={() => closeQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Close Question</Button>
                  <Button onClick={() => showAnswer(selectedQuestionID.R, selectedQuestionID.Q)}>Show Answers</Button>
                  <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
                  <Button onClick={() => showSummary()}>Show Summary</Button>
                  <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
                </div>
                <PointsManager ws={ws} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} currentQuestion={currentQuestion} pointsForTeams={pointsForTeams} />
              </div>
            )
          case questiontype.SONG:
            return (
              <div>

              </div>
            )
          case questiontype.BUZZER:
            return (
              <div>
                <BuzzerQuestion currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                <div className='controlButtonsGrid'>

                  <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
                  <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
                  <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
                  <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <Button onClick={() => closeQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Close Question</Button>
                  <Button onClick={() => showAnswer(selectedQuestionID.R, selectedQuestionID.Q)}>Show Answers</Button>
                  <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
                  <Button onClick={() => showSummary()}>Show Summary</Button>
                  <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
                </div>
                <PointsManager ws={ws} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} currentQuestion={currentQuestion} pointsForTeams={pointsForTeams} />
              </div>
            )
          case questiontype.MAP:
            return (
              <div>
                <MapQuestion currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                {/*<C_FreeText currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />*/}
                <div className='controlButtonsGrid'>

                  <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
                  <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
                  <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
                  <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <Button onClick={() => closeQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Close Question</Button>
                  <Button onClick={() => showAnswer(selectedQuestionID.R, selectedQuestionID.Q)}>Show Answers</Button>
                  <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
                  <Button onClick={() => showSummary()}>Show Summary</Button>
                  <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
                </div>
                <PointsManager ws={ws} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} currentQuestion={currentQuestion} pointsForTeams={pointsForTeams} />
              </div>
            )

          case questiontype.FREETEXT:
            return (
              <div>
                <FreeTextQuestion currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                {/*<C_FreeText currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />*/}
                <div className='controlButtonsGrid'>

                  <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
                  <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
                  <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
                  <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <Button onClick={() => closeQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Close Question</Button>
                  <Button onClick={() => showAnswer(selectedQuestionID.R, selectedQuestionID.Q)}>Show Answers</Button>
                  <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
                  <Button onClick={() => showSummary()}>Show Summary</Button>
                  <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
                </div>
                <PointsManager ws={ws} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} currentQuestion={currentQuestion} pointsForTeams={pointsForTeams} />
              </div>
            )
          case questiontype.FREENUMBER:
            return (
              <div>
                {/*<C_FreeText currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />*/}
                <FreeNumberQuestion currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} />
                <div className='controlButtonsGrid'>

                  <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
                  <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
                  <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
                  <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <Button onClick={() => closeQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Close Question</Button>
                  <Button onClick={() => showAnswer(selectedQuestionID.R, selectedQuestionID.Q)}>Show Answers</Button>
                  <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
                  <Button onClick={() => showSummary()}>Show Summary</Button>
                  <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
                </div>
                <PointsManager ws={ws} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} currentQuestion={currentQuestion} pointsForTeams={pointsForTeams} />
              </div>
            )
          case questiontype.WHOKNOWSMORE:
            ////console.log("Switch Case" + JSON.stringify(answersFromTeams, null, 2));
            return (
              <div>
                <WhoKnowsMoreQuestion currentQuestion={currentQuestion} teamAnswers={answersFromTeams} currentQuestionState={currentQuestionState} ws={ws} selectedAnswers={selectedAnswers} />
                <div className='controlButtonsGrid'>

                  <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
                  <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
                  <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
                  <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
                  <Button onClick={() => closeQuestion(selectedQuestionID.R, selectedQuestionID.Q)}>Close Question</Button>
                  <Button onClick={() => showAnswer(selectedQuestionID.R, selectedQuestionID.Q)}>Show Answers</Button>
                  <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
                  <Button onClick={() => showSummary()}>Show Summary</Button>
                  <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
                </div>
                <PointsManager ws={ws} teamsAndPlayers={teamAndPlayers.teams} teamAnswers={answersFromTeams} currentQuestion={currentQuestion} pointsForTeams={pointsForTeams} />
              </div>
            )

          default:
            break;
        }


      } else {
        return (
          <div className='controlButtonsGrid'>
            <div className='controlButtonsGrid'>
              <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
              <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
              <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
              <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <Button onClick={() => showSummary()}>Show Summary</Button>
              <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
            </div>
          </div>
        )
      }
      break
    case viewMode.STARTROUND:

      var rounds = []
      if (gameData !== undefined) {


        for (var r in gameData.gameConfig) {
          if (gameData.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            if (gameData.gameConfig[r].roundID === currentRound) {
              rounds.push(gameData.gameConfig[r])
            }
          }
        }
        return (
          <div className="infoBoard">
            <div className="infoBox">
              <div className='question'>
                Round {currentRound}
              </div>
              <div className="catbubble">
                {Object.entries(rounds).map(([key, value]) =>
                  <div>
                    {value.category}<br />
                    Questions: {value.nQuestions}<br />
                  </div>
                )}
              </div>

            </div>
            <div className='controlButtonsGrid'>

              <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
              <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
              <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
              <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <Button onClick={() => showSummary()}>Show Summary</Button>
              <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
            </div>
          </div>



        )
      }
      break;

    case viewMode.ENDROUND:
      var rounds = []
      if (gameData !== undefined) {
        for (var r in gameData.gameConfig) {
          if (gameData.gameConfig.hasOwnProperty(r) && r !== "nRounds") {
            if (gameData.gameConfig[r].roundID === currentRound) {
              rounds.push(gameData.gameConfig[r])
            }
          }
        }
        return (
          <div className="infoBoard">
            <div className="infoBox">
              <div className='question'>
                End of Round {currentRound}
              </div>


            </div>
            <div className='controlButtonsGrid'>

              <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
              <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
              <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
              <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
              <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
              <Button onClick={() => showSummary()}>Show Summary</Button>
              <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
            </div>
          </div>



        )
      }

      break;

    case viewMode.SCOREBORD:
      return (

        <div className="infoBoard">
          <div className="infoBox">
            <div className='question'>
              Scoreboard
            </div>
          </div>

          <div className='controlButtonsGrid'>

            <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
            <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
            <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
            <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
            <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
            <Button onClick={() => showSummary()}>Show Summary</Button>
            <Button onClick={() => setNextQuestionId()}>Set Next Question ID</Button>
            <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
          </div>
        </div>

      )

      break;


    default:
      return (
        <div className='controlButtonsGrid'>

          <RoundInput roundNumberCallback={handleChangeOnRound} selectedQuestionID={selectedQuestionID} />
          <QuestionInput questionNumberCallback={handleChangeOnQuestion} selectedQuestionID={selectedQuestionID} />
          <RoundStart ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
          <RoundEnd ws={ws} selectedQuestionID={selectedQuestionID} />
          <QuestionLoad ws={ws} selectedQuestionID={selectedQuestionID} setCurrentRoundCallback={setCurrentRound} />
          <Button onClick={() => showSummary()}>Show Summary</Button>
          <Button onClick={() => showScoreboard()}>Show Scoreboard</Button>
        </div>
      )


  }




};

export default (Controler);
