
  import React, { useState, useEffect } from 'react';
  import Button from "./Button.js";
  import { useStopwatch } from "react-use-precision-timer";
  
  
  const questionState =
  {
    OPEN: 1,
    CLOSED: 2,
    SHOWANSWERS: 3,
    SHOWPOINTS: 4,
  }
  
  
  
  
  function BuzzerQuestion({ currentQuestion, syncAnswer, currentQuestionState,ws,currentPoints }) {
     
    const stopwatch = useStopwatch();
  
    console.log("Buzzer Question")

    
  
    var lockedIn = syncAnswer.lockedIn;
    var questionStateText = "";
  
    
  
  
   
  
    function submitAnswer() {
      //console.log(stopwatch.getElapsedStartedTime())
      const answerMsg = {"type": "Panswer","questionID": currentQuestion.questionID,"buzzerTime": stopwatch.getElapsedStartedTime()/1000,"answerData":""}
      if (ws.readyState === WebSocket.OPEN) {
        console.log("send answer")
        ws.send(JSON.stringify(answerMsg));
      }
    }
  
    useEffect(() => {
      if (currentQuestionState === questionState.OPEN) {
        console.log("stopwatch started")
        stopwatch.start()
      }
    
    }, [currentQuestionState]);
  
  
  
  
  
    var active =0;
    var questionStateText="";
  
  
    switch (currentQuestionState){
      case questionState.SHOWANSWERS:
         active =currentQuestion.correctAnswer;
         questionStateText="showanswer";
         break;
      case questionState.OPEN:
          active =0;
          questionStateText="open";
          break;
      case questionState.CLOSED:
        questionStateText="closed";
        break
        case questionState.SHOWPOINTS:
          questionStateText="points";
          break
      default:
          active=0;
          break
  
    }
  
  

  switch (currentQuestionState) {
    case questionState.SHOWANSWERS:
      questionStateText = "showanswer";
      return (
        <div>
         <div className="questionCatClosed">{currentQuestion.category}</div>
         <div className="questionClosed">{currentQuestion.question}</div>
         
         <div className='centerPlayer'>
          <button className={lockedIn?"buzzerbuttonLocked":"buzzerbutton"} onClick={() => submitAnswer()}>Buzzer</button>
        </div>

        </div>
      )
  
  
      break;
    case questionState.OPEN:
      
    questionStateText = "open";
      return (
        <div>
        <div className={currentQuestionState===1?"questionCat":"questionCatClosed"}>{currentQuestion.category}</div>
        <div className={currentQuestionState===1?"question":"questionClosed"}>{currentQuestion.question}</div>
   
        <div className='centerPlayer'>
          <button className={lockedIn?"buzzerbuttonLocked":"buzzerbutton"} onClick={() => submitAnswer()}>Buzzer</button>
        </div>
      
        </div>
      )
      
      
      
      break;
    case questionState.CLOSED:
      questionStateText = "closed";
      return (
        <div>
         
         <div className="questionCatClosed">{currentQuestion.category}</div>
         <div className="questionClosed">{currentQuestion.question}</div>

         <div className='centerPlayer'>
          <button className={lockedIn?"buzzerbuttonLocked":"buzzerbutton"} onClick={() => submitAnswer()}>Buzzer</button>
        </div>
  
      
        </div>
      )
  
      break
    case questionState.SHOWPOINTS:
      questionStateText = "points";
      return (
        <div>
          
      
          <div className={currentPoints>0?"playerCatAnswerCorrect":"playerCatAnswerWrong"}>{currentQuestion.category}</div>
          <div className={currentPoints>0?"playerAnswerCorrect":"playerAnswerWrong"}>{currentQuestion.question}</div>

          <div className='centerPlayer'>
          <button className={lockedIn?"buzzerbuttonLocked":"buzzerbutton"} onClick={() => submitAnswer()}>Buzzer</button>
        </div>
      
         
      
        </div>
      )
      
      break
    default:
      break
  
  }
  
  
     
  }

export default (BuzzerQuestion);